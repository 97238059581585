<!-- 配置工具首页--父组件 -->
<template>
  <div class="manage_trouble">
    <div class="container">
      <div class="tool_card">
        <div class="tool_card_left">
          <div class="corver_box tool_card_item">
            <img :src="toolInfo.image" alt="" />
          </div>
          <div class="tittle tool_card_item">
            <span>{{ toolInfo.questionnaireName }}</span>
          </div>
          <div class="tool_card_item">
            <i></i>
          </div>
          <div class="count tool_card_item">
            <span>(共{{ problemNum }}个题)</span>
          </div>
        </div>
        <div class="tool_card_right">
          <div class="btn_list">
            <div v-if="active == 1">
              <el-button type="primary" @click="previewQuestionnaire">预览问卷</el-button>
              <el-button type="info" @click="addProblemGroupBtn" plain>新增问题组</el-button>
            </div>
            <div v-else-if="active == 2">
              <el-button type="primary" @click="previewQuestionnaire">预览问卷</el-button>
            </div>
            <div v-else>
              <el-button plain @click="setManyConditionBtn()">认定条件</el-button>
              <el-button plain @click="setIsRequiredBtn(true)">设置必填</el-button>
              <el-button plain @click="setIsRequiredBtn(false)">设置非必填</el-button>
              <el-button plain @click="deleteGroupQuestions">批量移除</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="step_card">
        <div class="step_card_left">
          <div class="img_box">
            <img src="@/assets/image/config-problem-step.png" alt="" />
          </div>
          <div class="weight_tips">
            <span class="color2">所有一级问题组已分配权重</span>
            <span class="color1">{{ weight.isSetWeight }}</span>
            <span class="color2">，可分配权重</span>
            <span class="color1">{{ weight.noSetWeight }}</span>
          </div>
        </div>
        <div class="step_card_right">
          <div class="step_box">
            <el-steps :active="active" align-center>
              <el-step title="1.配置问题组"></el-step>
              <el-step title="2.选择问题"></el-step>
              <el-step title="3.设置问题属性"></el-step>
            </el-steps>
          </div>
        </div>
      </div>
      <configTroubleGroup
        v-if="active == 1"
        :active="active"
        :id="id"
        :new="newConfigTroubleGroup"
        @editProblemGroup="editProblemGroup"
        @getWeight="getWeight"
        @getProblemNum="getProblemNum"
        @toConfigProblem="toConfigProblem"
      ></configTroubleGroup>
      <selectTrouble
        :id="id"
        v-else-if="active == 2"
        :active="active"
        :questionnaireId="questionnaireId"
        @goBack="goBack"
      ></selectTrouble>
      <setTroubleProperty
        v-else
        :active="active"
        :id="id"
        :new="newSetTroubleProperty"
        :conditionDialogVisible="conditionDialogVisible"
        @changeVisible="changeVisible"
        @goBack="goBack"
        @getIds="getIds"
        @editProblemGroup="editProblemGroup"
        @toConfigProblem="toConfigProblem"
      ></setTroubleProperty>
    </div>
    <el-dialog
      :title="echo == '0' ? '新增问题组' : '编辑问题组'"
      :visible.sync="addProblemGroupDialogVisible"
      :before-close="addProblemGroupDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="tool_info_card">
        <div class="row1">
          <div class="mini_img_box">
            <img :src="toolInfo.image" alt="" />
          </div>
          <div class="tittle">
            <span>{{ toolInfo.questionnaireName }}</span>
          </div>
          <div class="weight_tips">
            <span class="color2">当前一级问题组已分配权重</span>
            <span class="color1">{{ weight.isSetWeight }}</span>
            <span class="color2">，可分配权重</span>
            <span class="color1">{{ weight.noSetWeight }}</span>
          </div>
        </div>
      </div>
      <div class="dialog_content">
        <div class="dialog_content_name">
          <span>问题组</span>
        </div>
        <!-- 问题组集合 -->
        <div class="group_item_list">
          <!-- 一级问题组 -->
          <div class="group_item" v-for="(item, index) in groups" :key="index">
            <div class="group_item_content">
              <div class="row" v-if="echo == '0'">
                <el-radio-group v-model="item.level" @change="groupLevelSelected($event, item)">
                  <el-radio label="1">一级问题组</el-radio>
                  <el-radio label="2">二级问题组</el-radio>
                  <el-radio label="3">三级问题组</el-radio>
                </el-radio-group>
              </div>
              <div class="row">
                <div class="problem_group_name_input">
                  <span>问题组名称:</span>
                  <el-input v-model="item.groupName" placeholder="请输入问题组名称"></el-input>
                </div>
                <div class="problem_group_weight_input">
                  <span>权重:</span>
                  <el-input
                    v-model="item.weight"
                    placeholder="请输入0~1以内的两位小数"
                    @change="groupWeightChanged"
                    oninput="value=value.replace(/^\+?(\d*\.\d{3})$/,'')"
                  ></el-input>
                </div>
              </div>
              <!-- 二级问题组 -->
              <div v-if="item.level != '1' || echo == '1'">
                <div class="group_item" v-for="(i1, i2) in item.groups" :key="i2">
                  <div class="group_item_content">
                    <div class="row">
                      <span class="problem_group_tittle">二级问题组</span>
                      <div class="weight_tips">
                        <span class="color2">当前二级问题组已分配权重</span>
                        <span class="color1">{{ i1.isSetWeight }}</span>
                        <span class="color2">，可分配权重</span>
                        <span class="color1">{{ i1.noSetWeight }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="problem_group_name_input">
                        <span>问题组名称:</span>
                        <el-input v-model="i1.groupName" placeholder="请输入问题组名称"></el-input>
                      </div>
                      <div class="problem_group_weight_input">
                        <span>权重:</span>
                        <el-input
                          v-model="i1.weight"
                          placeholder="请输入0~1以内的两位小数"
                          @change="groupTwoWeightChanged($event, item)"
                          oninput="value=value.replace(/^([^01]|(0(\.\d{2}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
                        ></el-input>
                      </div>
                    </div>
                    <!-- 三级问题组 -->
                    <div v-if="item.level == '3' || echo == '1'">
                      <div v-for="(ii1, ii2) in i1.groups" :key="ii2">
                        <div class="row" v-if="ii2 == 0">
                          <span class="problem_group_tittle">三级问题组</span>
                          <div class="weight_tips">
                            <span class="color2">当前三级问题组已分配权重</span>
                            <span class="color1">{{ ii1.isSetWeight }}</span>
                            <span class="color2">，可分配权重</span>
                            <span class="color1">{{ ii1.noSetWeight }}</span>
                          </div>
                        </div>
                        <div>
                          <div class="group_level3_item_content">
                            <div class="row">
                              <div class="problem_group_name_input">
                                <span>问题组名称:</span>
                                <el-input
                                  v-model="ii1.groupName"
                                  placeholder="请输入问题组名称"
                                ></el-input>
                              </div>
                              <div class="problem_group_weight_input">
                                <span>权重:</span>
                                <el-input
                                  v-model="ii1.weight"
                                  placeholder="请输入0~1以内的两位小数"
                                  @change="groupThreeWeightChanged($event, i1)"
                                  oninput="value=value.replace(/^([^01]|(0(\.\d{2}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
                                ></el-input>
                              </div>
                              <div class="icon_box">
                                <i
                                  class="el-icon-circle-plus"
                                  v-if="ii2 == 0"
                                  @click="addThreeProblemGroupItem(i1)"
                                ></i>
                                <i
                                  class="el-icon-remove"
                                  v-else
                                  @click="deleteThreeProblemGroupItem(i1, ii2)"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 三级问题组-底部 -->
                  </div>
                  <div class="icon_box">
                    <i
                      class="el-icon-circle-plus"
                      v-if="i2 == 0"
                      @click="addTwoProblemGroupItem(item)"
                    ></i>
                    <i
                      class="el-icon-remove"
                      v-else
                      @click="deleteTwoProblemGroupItem(item, i2)"
                    ></i>
                  </div>
                </div>
              </div>
              <!-- 二级问题组-底部 -->
            </div>
            <div class="icon_box" v-if="echo == '0'">
              <i class="el-icon-circle-plus" v-if="index == 0" @click="addOneProblemGroupItem"></i>
              <i
                class="el-icon-remove"
                v-if="index > 0"
                @click="deleteOneProblemGroupItem(index)"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAddProblemGroup">保存</el-button>
        <el-button @click="addProblemGroupDialogClosed">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { accSub, accAdd } from '@/utils/function.js'
import configTroubleGroup from './configTroubleGroup.vue'
import selectTrouble from './selectTrouble.vue'
import setTroubleProperty from './setTroubleProperty.vue'
import {
  getQuestionnaireDetail,
  saveGroup,
  selectGroup,
  updateGroup,
  deleteGroupQuestions,
  updateIsRequired
} from '@/api/diagnoseTool.js'

export default {
  name: 'manageTrouble',
  components: { configTroubleGroup, selectTrouble, setTroubleProperty },
  data() {
    return {
      conditionDialogVisible: false, // 设置条件弹窗
      echo: '0', //  0是新增，1是编辑
      deleteGroupIds: [], // 要删除的问题组id集合
      deleteQuestionsParam: {
        ids: [],
        optionIds: []
      }, //批量删除问题的传参
      problemNum: 0, //已配置的问题总数
      weight: {
        isSetWeight: 0,
        noSetWeight: 0
      },
      newConfigTroubleGroup: 0, //新增时改变该数据
      newSetTroubleProperty: 0, //设置必填后改变该数据
      id: null, //工具id
      ids: [], //选中的问题组与问题关联id集合
      questionnaireId: null, //问题组id
      toolInfo: {}, //工具详情
      active: 1, //步骤条激活的step
      addProblemGroupDialogVisible: false, //新增问题组弹窗
      //新增问题组的传参
      groups: [
        {
          level: '1',
          questionnaireId: null, //问题组id
          groupName: '',
          isSetWeight: 0, //已设置的权重值
          noSetWeight: 0, //剩余可设置的权重值
          weight: null,
          groups: null
        }
      ],

      aa: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.search()
  },
  methods: {
    //查询工具详细信息
    async search() {
      const { data: res } = await getQuestionnaireDetail({ id: this.id })
      if (res.resultCode === 200) {
        this.toolInfo = res.data
      } else {
        this.$message.error('获取工具详细信息失败!')
      }
    },
    // 关闭弹窗
    changeVisible(val) {
      this.conditionDialogVisible = val
    },
    //  打开设置条件弹窗
    setManyConditionBtn() {
      this.conditionDialogVisible = true
    },
    //批量删除问题
    async deleteGroupQuestions() {
      const { data: res } = await deleteGroupQuestions(this.deleteQuestionsParam)
      if (res.resultCode === 200) {
        this.$message.success('批量删除问题成功!')
        this.newSetTroubleProperty++
      } else {
        this.$message.error('批量删除问题失败!')
      }
    },
    //新增问题组
    addProblemGroupBtn() {
      this.echo = '0'
      this.addProblemGroupDialogVisible = true
    },
    //编辑问题组
    editProblemGroup(item) {
      this.echo = '1'
      this.groups = [item]
      this.addProblemGroupDialogVisible = true
    },
    //预览问卷
    previewQuestionnaire() {
      this.active = 3
    },
    //设置必填按钮
    async setIsRequiredBtn(bol) {
      if (this.ids.length > 0) {
        const { data: res } = await updateIsRequired({
          id: this.ids,
          isRequired: bol
        })
        if (res.resultCode === 200) {
          this.$message.success('设置成功!')
          this.newSetTroubleProperty++
        } else {
          this.$message.error('设置失败!')
        }
      } else {
        this.$message.warning('请先选择问题!')
      }
    },
    //预览问卷界面 获取选中的问题数组id
    getIds(obj) {
      this.ids = obj.ids
      this.deleteQuestionsParam = obj
    },
    //获取一级问题组已分配和未分配权重
    getWeight(obj) {
      // console.log(obj)
      this.weight = obj
    },
    //获取问题总个数
    getProblemNum(num) {
      this.problemNum = num
    },
    //选择问题组等级
    groupLevelSelected(val, item) {
      if (val == '2') {
        item.groups = []
        item.groups.push({
          questionnaireId: null,
          groupName: '',
          isSetWeight: 0, //已设置的权重值
          noSetWeight: 0, //剩余可设置的权重值
          weight: null,
          groups: null //三级级问题组
        })
      } else if (val == '3') {
        item.groups = []
        item.groups.push({
          questionnaireId: null,
          groupName: '',
          isSetWeight: 0, //已设置的权重值
          noSetWeight: 0, //剩余可设置的权重值
          weight: null,
          groups: [
            {
              questionnaireId: null,
              groupName: '',
              isSetWeight: 0, //已设置的权重值
              noSetWeight: 0, //剩余可设置的权重值
              weight: null,
              groups: null //问题
            }
          ]
        })
      } else {
        item.groups = null
      }
    },
    //一级问题组设置权重
    groupWeightChanged(val) {
      //已设置权重需要重新梳理逻辑！！！
      let isSetWeight = 0
      this.groups.forEach((el) => {
        isSetWeight = accAdd(Number(isSetWeight), Number(el.weight))
      })
      this.groups.forEach((el) => {
        el.isSetWeight = isSetWeight
        el.noSetWeight = Number(accSub(1, Number(el.isSetWeight)))
      })
    },
    //二级问题组设置权重
    groupTwoWeightChanged(val, item) {
      let isSetWeight = 0
      item.groups.forEach((el) => {
        isSetWeight = accAdd(Number(isSetWeight), Number(el.weight))
      })
      item.groups.forEach((el) => {
        el.isSetWeight = isSetWeight
        el.noSetWeight = Number(accSub(1, Number(el.isSetWeight)))
      })
    },
    //三级问题组设置权重
    groupThreeWeightChanged(val, i1) {
      let isSetWeight = 0
      i1.groups.forEach((el) => {
        isSetWeight = accAdd(Number(isSetWeight), Number(el.weight))
      })
      i1.groups.forEach((el) => {
        el.isSetWeight = isSetWeight
        el.noSetWeight = Number(accSub(1, Number(el.isSetWeight)))
      })
    },
    //添加二级问题组方法 没有三级问题组
    pushTwoProblemGroup(item) {
      item.groups.push({
        questionnaireId: null,
        groupName: '',
        weight: null,
        groups: null,
        isSetWeight: 0, //已设置的权重值
        noSetWeight: 0 //剩余可设置的权重值
      })
    },
    //添加二级问题组方法  有三级问题组
    pushThreeProblemGroup(item) {
      item.groups.push({
        questionnaireId: null,
        groupName: '',
        weight: null,
        isSetWeight: 0, //已设置的权重值
        noSetWeight: 0, //剩余可设置的权重值
        groups: [
          {
            questionnaireId: null,
            groupName: '',
            isSetWeight: 0, //已设置的权重值
            noSetWeight: 0, //剩余可设置的权重值
            weight: null,
            groups: null
          }
        ]
      })
    },
    //添加一级问题组item
    addOneProblemGroupItem() {
      this.groups.push({
        level: '1',
        groupName: '',
        isSetWeight: 0, //已设置的权重值
        noSetWeight: 0, //剩余可设置的权重值
        weight: null,
        groups: null
      })
    },
    //删除一级问题组item
    deleteOneProblemGroupItem(index) {
      this.groups.splice(index, 1)
    },
    //添加二级问题组item
    addTwoProblemGroupItem(item) {
      if (this.echo == '0') {
        //新增
        if (item.level == '2') {
          //没有三级问题组
          this.pushTwoProblemGroup(item)
        } else {
          //有三级问题组
          this.pushThreeProblemGroup(item)
        }
      } else {
        //编辑
        if (!item.groups[0].groups) {
          //没有三级问题组
          this.pushTwoProblemGroup(item)
        } else {
          if (item.groups[0].groups.length == 0) {
            //没有三级问题组
            this.pushTwoProblemGroup(item)
          } else {
            //有三级问题组
            this.pushThreeProblemGroup(item)
          }
        }
      }
    },
    //删除二级问题组item
    deleteTwoProblemGroupItem(item, i2) {
      if (this.echo == '1' && item.groups[i2].questionnaireId) {
        this.deleteGroupIds.push(item.groups[i2].questionnaireId)
      }
      item.groups.splice(i2, 1)
    },
    //添加三级问题组item
    addThreeProblemGroupItem(i1) {
      i1.groups.push({
        questionnaireId: null,
        groupName: '',
        isSetWeight: 0, //已设置的权重值
        noSetWeight: 0, //剩余可设置的权重值
        weight: null,
        groups: null
      })
      // if (!i1.questionnaireId) {
      //   this.groupUpdateVos.push({
      //     questionnaireId: null,
      //     groupName: '',
      //     isSetWeight: 0, //已设置的权重值
      //     noSetWeight: 0, //剩余可设置的权重值
      //     weight: null,
      //     groups: null
      //   })
      // }
    },
    //删除三级问题组item
    deleteThreeProblemGroupItem(i1, ii2) {
      if (this.echo == '1' && i1.groups[ii2].questionnaireId) {
        this.deleteGroupIds.push(i1.groups[ii2].questionnaireId)
      }
      i1.groups.splice(ii2, 1)
    },
    //新增、编辑问题组弹窗  保存
    async saveAddProblemGroup() {
      if (this.echo == '0') {
        //新增
        let bol = true
        // let message = '0'
        // let level = '2'
        // if (this.groups[0].isSetWeight > 1) {
        //   this.$message.error('所有一级问题组的权重总和大于1，请先调整权重配置!')
        // } else if (this.groups[0].isSetWeight < 1) {
        //   this.$message.error('所有一级问题组的权重总和小于1，请先调整权重配置!')
        // } else {
        //   this.groups.forEach((item) => {
        //     if (item.groups) {
        //       //是否有二级问题组,有则判断权重，没有则保存
        //       if (item.groups.length > 0) {
        //         bol = false
        //         if (item.groups[0].isSetWeight > 1) {
        //           message = '1'
        //         } else if (item.groups[0].isSetWeight < 1) {
        //           message = '2'
        //         } else {
        //           item.groups.forEach((i) => {
        //             if (i.groups) {
        //               //是否有三级问题组,有则判断权重，没有则保存
        //               if (i.groups.length > 0) {
        //                 level = '3'
        //                 if (i.groups[0].isSetWeight > 1) {
        //                   message = '1'
        //                   bol = false
        //                 } else if (i.groups[0].isSetWeight < 1) {
        //                   message = '2'
        //                   bol = false
        //                 } else {
        //                   bol = true
        //                 }
        //               }
        //             } else {
        //               bol = true
        //             }
        //           })
        //         }
        //       }
        //     } else {
        //       bol = true
        //     }
        //   })
        // }
        if (bol) {
          const { data: res } = await saveGroup({
            groups: this.groups,
            id: this.id
          })
          if (res.resultCode === 200) {
            this.addProblemGroupDialogVisible = false
            this.newConfigTroubleGroup++
            this.groups = [
              {
                level: '1',
                questionnaireId: null, //问题组id
                groupName: '',
                isSetWeight: 0, //已设置的权重值
                noSetWeight: 0, //剩余可设置的权重值
                weight: null,
                groups: null
              }
            ]
            this.$message.success('新增成功!')
          } else {
            this.$message.error('新增失败!')
          }
        }
        // else {
        //   if (level == '2') {
        //     if (message == '1') {
        //       this.$message.error('存在二级问题组的权重总和大于1，请先调整权重配置!')
        //     } else {
        //       this.$message.error('存在二级问题组的权重总和小于1，请先调整权重配置!')
        //     }
        //   } else {
        //     if (message == '1') {
        //       this.$message.error('存在三级问题组的权重总和大于1，请先调整权重配置!')
        //     } else {
        //       this.$message.error('存在三级问题组的权重总和小于1，请先调整权重配置!')
        //     }
        //   }
        // }
      } else {
        //编辑
        let item = this.groups[0]
        let updateVos = []
        //添加一级问题组
        updateVos.push({
          groupId: null,
          groupName: item.groupName,
          id: item.questionnaireId,
          isSetWeight: item.isSetWeight,
          level: 1,
          noSetWeight: item.noSetWeight,
          orderNumber: null,
          weight: item.weight,
          updateVos: null,
          groupUpdateVos: null
        })
        if (item.groups) {
          //有二级问题组
          item.groups.forEach((el) => {
            updateVos.push({
              groupId: item.questionnaireId, //父id
              groupName: el.groupName, //问题组名称
              id: el.questionnaireId, //问题组id
              isSetWeight: el.isSetWeight, //已分配的权重
              level: 2, //问题组级别
              noSetWeight: el.noSetWeight, //可分配的权重
              orderNumber: null, //排序
              weight: el.weight, //问题组设置的权重
              updateVos: null, //子级的集合
              groupUpdateVos: null
            })
          })
          item.groups.forEach((el) => {
            if (el.groups) {
              //有三级问题组
              if (el.groups.length > 0) {
                if (el.questionnaireId) {
                  //二级问题组的id不为null
                  el.groups.forEach((i) => {
                    updateVos.push({
                      groupId: el.questionnaireId,
                      groupName: i.groupName,
                      id: i.questionnaireId,
                      isSetWeight: i.isSetWeight,
                      level: 3,
                      noSetWeight: i.noSetWeight,
                      orderNumber: null,
                      weight: i.weight
                    })
                  })
                } else {
                  //二级问题组id为null
                  let arr3 = []
                  el.groups.forEach((i) => {
                    arr3.push({
                      groupId: null,
                      groupName: i.groupName,
                      id: null,
                      isSetWeight: i.isSetWeight,
                      level: 3,
                      noSetWeight: i.noSetWeight,
                      orderNumber: null,
                      weight: i.weight
                    })
                  })
                  updateVos.forEach((i) => {
                    if (!i.id) {
                      i.groupUpdateVos = arr3
                    }
                  })
                }
              }
            }
          })
        }
        // console.log(updateVos)
        const { data: res } = await updateGroup({
          deleteGroupIds: this.deleteGroupIds,
          updateVos,
          questionnaireId: this.id
        })
        if (res.resultCode === 200) {
          this.deleteGroupIds = []
          this.addProblemGroupDialogVisible = false
          this.newConfigTroubleGroup++
          this.groups = [
            {
              level: '1',
              questionnaireId: null, //问题组id
              groupName: '',
              isSetWeight: 0, //已设置的权重值
              noSetWeight: 0, //剩余可设置的权重值
              weight: null,
              groups: null
            }
          ]
          this.$message.success('编辑成功!')
        } else {
          this.$message.error('编辑失败!')
        }
      }
    },
    //新增问题组弹窗关闭
    addProblemGroupDialogClosed() {
      this.deleteGroupIds = []
      this.groups = [
        {
          level: '1',
          groupName: '',
          weight: null,
          groups: null,
          isSetWeight: 0, //已设置的权重值
          noSetWeight: 0 //剩余可设置的权重值
        }
      ]
      this.addProblemGroupDialogVisible = false
    },
    //前往配置问题界面  带问题组id
    toConfigProblem(item) {
      this.active = 2
      this.questionnaireId = item.questionnaireId
    },
    //取消按钮
    goBack() {
      this.active = 1
    }
  }
}
</script>

<style lang="less" scoped>
/* 取消input的上下箭头 */
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

::v-deep .problem_group_weight_input input::-webkit-outer-spin-button,
::v-deep .problem_group_weight_input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep .problem_group_weight_input input[type='number'] {
  -moz-appearance: textfield;
}
.manage_trouble {
  height: calc(100% - 40px);
}
.tool_card {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 20px;
  .tool_card_left {
    display: flex;
    align-items: center;
    min-width: 30%;
    .tool_card_item {
      margin-right: 10px;
    }
    .corver_box {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #c9c9c9;
      overflow: hidden;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .tittle span {
      font-size: 24px;
      font-family: 'PingFang SC';
      color: #151515;
    }
    .count span {
      font-size: 16px;
      color: #d4d4d4;
    }
  }
}
.step_card {
  min-height: 100px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  .step_card_left {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-width: 360px;
    min-height: 80px;
    border-right: #c9c9c9 1px dotted;
    margin-left: 10px;
    margin-right: 40px;
    .img_box {
      width: 200px;
      height: 40px;
      margin-bottom: 10px;
      img {
        width: 200px;
        height: 40px;
      }
    }
  }
  .step_card_right .step_box {
    min-width: 600px;
  }
}
.tool_info_card {
  width: 100%;
  min-height: 60px;
  background-color: #fbfbfb;
  margin-bottom: 20px;
  padding: 10px 20px;
  box-sizing: border-box;
  .tittle {
    display: flex;
    font-size: 18px;
    color: #333;
    height: 100%;
    > span {
      width: 300px;
      display: block;
      margin-right: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }
  .weight_tips {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: auto;
    font-size: 14px;
  }
}
.dialog_content {
  display: flex;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .dialog_content_name {
    margin-right: 20px;
  }
  .group_item_list {
    width: 90%;
    display: flex;
    flex-flow: column;
    .group_item {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      .group_item_content {
        width: 100%;
        padding: 20px;
        padding-right: 40px;
        border: #c4c4c4 1px dotted;
        box-sizing: border-box;
        .row {
          .problem_group_tittle {
            min-width: 80px;
            font-size: 14px;
            color: #4e93fb;
          }
        }
        .problem_group_name_input {
          display: flex;
          align-items: center;
          width: 60%;
          margin-right: 20px;
          padding-left: 10px;
          box-sizing: border-box;
          span {
            font-size: 14px;
            min-width: 90px;
          }
        }
        .problem_group_weight_input {
          display: flex;
          align-items: center;
          flex: 1;
          padding-left: 10px;
          box-sizing: border-box;
          span {
            font-size: 14px;
            min-width: 50px;
          }
        }
      }
    }
  }
}
.row1 {
  display: flex;
  align-items: center;
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
}
.border_top {
  border-top: #999 1px dotted;
}
.last_row {
  margin-bottom: 0 !important;
}
.icon_box {
  display: flex;
  align-items: center;
  height: 40px;
  i {
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #4e93fb;
    margin-left: 10px;
  }
}
.mini_img_box {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }
}
.weight_tips {
  min-width: 360px;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #bebebe;
}
</style>
