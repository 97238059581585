<!-- 问题组列表 -->
<template>
  <div class="config_trouble_group">
    <div class="problem_group_card_list">
      <div class="problem_group_card" v-for="(item, index) in problemGroupList" :key="index">
        <!-- 一级问题组 -->
        <div class="level_content">
          <div class="tittle">
            <div class="tittle_txt">{{ item.groupName }}</div>
            <div class="i1_box">
              <i class="el-icon-edit color5" @click="editGroup(item)"></i>
              <i class="el-icon-delete color4" @click="deleteGroup(item)"></i>
            </div>
            <div class="txt_btn" v-if="item.groups">
              <span v-if="item.groups.length < 1" @click="toConfigProblem(item)" class="color1"
                >配置问题</span
              >
            </div>
          </div>
          <div class="row">
            <div class="img_box">
              <img src="@/assets/image/diagnosticTools/problem-icon.png" alt="" />
            </div>
            <span>{{ item.questionsNumber }}题</span>
          </div>
          <div class="row">
            <div class="img_box">
              <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
            </div>
            <span>{{ item.weight }}</span>
          </div>
          <div class="last_row">
            <div class="txt color5">
              <div v-if="item.groups">
                <div v-if="item.groups.length > 0">
                  <div class="img_box" v-if="item.groups[0].isSetWeight != 1">
                    <img src="@/assets/image/diagnosticTools/warning-icon.png" alt="" />
                  </div>
                </div>
              </div>
              <div>当前一级问题组已分配权重</div>
              <div class="color1" v-if="item.groups">
                {{ item.groups.length > 0 ? item.groups[0].isSetWeight : '1' }}
              </div>
              <div class="color1" v-else>1</div>
              <div>，可分配权重</div>
              <div class="color1" v-if="item.groups">
                {{ item.groups.length > 0 ? item.groups[0].noSetWeight : '0' }}
              </div>
              <div class="color1" v-else>0</div>
            </div>
            <div class="problem_group_level_tag level1_color" @click="showLevelTwoBox(item, index)">
              <span class="color1">一级问题组</span>
              <div v-if="item.groups">
                <div class="i_box" v-if="item.groups.length > 0">
                  <i class="el-icon-arrow-up color1" v-if="!item.isExit"></i>
                  <i class="el-icon-arrow-right color1" v-else></i>
                </div>
              </div>
            </div>
          </div>
          <!-- 二级问题组 -->
          <div v-if="item.groups">
            <div class="show_level2_box" v-if="item.groups.length > 0 && !item.isExit">
              <div class="level2_box">
                <div class="row">
                  <div class="search_input">
                    <el-input
                      placeholder="请输入"
                      v-model="queryGroup.queryConditions"
                      class="input-with-select"
                    >
                      <el-button slot="append" type="primary" @click="searchTwo(item)"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
                <div class="level2_box_bottom">
                  <div class="level2_content" v-for="(i1, i2) in item.groups" :key="i2">
                    <div class="level2_tittle">
                      <div class="tittle_txt">{{ i1.groupName }}</div>
                      <div class="i1_box">
                        <!-- <i class="el-icon-edit color5" @click="editGroup(i1)"></i> -->
                        <i class="el-icon-delete color4" @click="deleteGroup(i1)"></i>
                      </div>
                      <div class="txt_btn" v-if="i1.groups">
                        <span
                          v-if="i1.groups.length < 1"
                          @click="toConfigProblem(i1)"
                          class="color1"
                          >配置问题</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="img_box">
                        <img src="@/assets/image/diagnosticTools/problem-icon.png" alt="" />
                      </div>
                      <span>{{ i1.questionsNumber }}题</span>
                    </div>
                    <div class="row">
                      <div class="img_box">
                        <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                      </div>
                      <span>{{ i1.weight }}</span>
                    </div>
                    <div class="last_row">
                      <div class="txt color5">
                        <div>当前二级问题组已分配权重</div>
                        <div class="color1" v-if="i1.groups">
                          {{ i1.groups.length > 0 ? i1.groups[0].isSetWeight : '1' }}
                        </div>
                        <div class="color1" v-else>1</div>
                        <div>，可分配权重</div>
                        <div class="color1" v-if="i1.groups">
                          {{ i1.groups.length > 0 ? i1.groups[0].noSetWeight : '0' }}
                        </div>
                        <div class="color1" v-else></div>
                      </div>
                      <div
                        class="problem_group_level_tag level2_color"
                        @click="showLevelThreeBox(item, i1, i2)"
                      >
                        <span class="color2">二级问题组</span>
                        <div v-if="i1.groups">
                          <div class="i_box" v-if="i1.groups.length > 0">
                            <i class="el-icon-arrow-right color2" v-if="i1.isExit"></i>
                            <i class="el-icon-arrow-up color2" v-else></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 三级问题组 -->
                    <transition name="fade">
                      <div v-if="i1.groups">
                        <div class="level3_box" v-show="i1.groups.length > 0 && !i1.isExit">
                          <div class="row">
                            <div class="search_input">
                              <el-input
                                placeholder="请输入"
                                v-model="queryGroup.queryConditions"
                                class="input-with-select"
                              >
                                <el-button slot="append" type="primary" @click="searchTwo(i1)"
                                  >搜索</el-button
                                >
                              </el-input>
                            </div>
                          </div>
                          <div class="level3_box_bottom">
                            <div class="level3_content" v-for="(ii1, ii2) in i1.groups" :key="ii2">
                              <div class="level2_tittle">
                                <div class="tittle_txt">{{ ii1.groupName }}</div>
                                <div class="i1_box">
                                  <!-- <i class="el-icon-edit color5" @click="editGroup(i1)"></i> -->
                                  <i class="el-icon-delete color4" @click="deleteGroup(ii1)"></i>
                                </div>
                                <div class="txt_btn">
                                  <div class="txt_btn">
                                    <span @click="toConfigProblem(ii1)" class="color1"
                                      >配置问题</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="img_box">
                                  <img
                                    src="@/assets/image/diagnosticTools/problem-icon.png"
                                    alt=""
                                  />
                                </div>
                                <span>{{ ii1.questionsNumber }}题</span>
                              </div>
                              <div class="row">
                                <div class="img_box">
                                  <img
                                    src="@/assets/image/diagnosticTools/weight-icon.png"
                                    alt=""
                                  />
                                </div>
                                <span>{{ ii1.weight }}</span>
                              </div>
                              <div class="last_row">
                                <div class="problem_group_level_tag level3_color">
                                  <span class="color3">三级问题组</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectGroup, deleteGroup, selectTwoGroup } from '@/api/diagnoseTool.js'
import { accSub, accAdd } from '@/utils/function.js'

export default {
  name: 'configTroubleGroup',
  components: {},
  props: {
    id: {},
    new: {},
    active: {
      type: Number
    }
  },
  data() {
    return {
      queryInfo: {},
      queryGroup: {
        queryConditions: ''
      },
      problemGroupList: [] //问题组列表
    }
  },
  watch: {
    new: {
      //父组件改变了new的值 重新调用查询一览接口
      handler() {
        if (this.new != 0) {
          this.search()
        }
      },
      immediate: true
    },
    active: {
      handler() {
        if (this.active == 1) {
          this.search()
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    // 查询问题组一览
    async search() {
      const { data: res } = await selectGroup({ questionnaireId: this.id })
      if (res.resultCode === 200) {
        this.problemGroupList = res.data
        let obj = {
          isSetWeight: 0,
          noSetWeight: 0
        }
        let num = 0
        //  获取所有一级问题组已设置的权重
        this.problemGroupList.forEach((item) => {
          //obj.isSetWeight = Number(accAdd(Number(obj.isSetWeight) + Number(item.weight)))
          num = num + item.questionsNumber
          if (item.weight) {
            obj.isSetWeight = Number(accAdd(Number(obj.isSetWeight), Number(item.weight)))
          }
        })
        obj.noSetWeight = Number(accSub(1, Number(obj.isSetWeight)))
        //obj.noSetWeight = 1 - obj.isSetWeight
        this.$emit('getWeight', obj)
        this.$emit('getProblemNum', num)
      } else {
        this.$message.error('获取问题组信息失败!')
      }
    },
    // 查询二级、三级问题组
    async searchTwo(item) {
      const groupName = this.queryGroup.queryConditions ? this.queryGroup.queryConditions : '所有'
      const { data: res } = await selectTwoGroup({
        questionnaireId: this.id,
        groupName
      })
      if (res.resultCode === 200) {
        if (res.data.length > 0) {
          item.groups = res.data
        } else {
          this.$message.warning('请更换关键词搜索~')
        }
        // let obj = {
        //   isSetWeight: 0,
        //   noSetWeight: 0
        // }
        // let num = 0
        // this.problemGroupList.forEach((item) => {
        //   obj.isSetWeight = Number(accAdd(Number(obj.isSetWeight), Number(item.weight)))
        //   num = num + item.questionsNumber
        // })
        // obj.noSetWeight = Number(accSub(1, Number(obj.isSetWeight)))
        // //obj.noSetWeight = 1 - obj.isSetWeight
        // this.$emit('getWeight', obj)
        // this.$emit('getProblemNum', num)
      } else {
        this.$message.error('获取问题组信息失败!')
      }
    },
    //打开编辑问题组弹窗
    editGroup(item) {
      this.$emit('editProblemGroup', item)
    },
    //删除问题组
    deleteGroup(item) {
      //this.$confirm('确认是否删除？', '提示')
      this.$confirm('确认是否删除' + item.groupName + '？', '')
        .then(async () => {
          const { data: res } = await deleteGroup({
            id: item.questionnaireId, //问题组id
            questionnaireId: this.id //问卷id
          })
          if (res.resultCode == 200) {
            this.$message.success('删除成功!')
            this.search()
          } else {
            this.$message.error('删除失败!')
          }
        })
        .catch(() => {})
    },
    //查询子级问题组
    async searchGroupChildren() {},
    //控制是否显示二级问题组
    showLevelTwoBox(item, index) {
      if (item.groups) {
        if (item.groups.length > 0) {
          item.isExit = !item.isExit
          this.problemGroupList.map((el, i) => {
            if (index != i && el.isExit == false) {
              el.isExit = !el.isExit
            }
          })
        }
      }
    },
    //控制是否显示三级问题组
    showLevelThreeBox(item, i1, i2) {
      if (i1.groups) {
        if (i1.groups.length > 0) {
          i1.isExit = !i1.isExit
          item.groups.map((el, i) => {
            if (i2 != i && el.isExit == false) {
              el.isExit = true
            }
          })
        }
      }
    },
    // 配置问题按钮 传问题组id
    toConfigProblem(item) {
      this.$emit('toConfigProblem', item)
    }
  }
}
</script>

<style lang="less" scoped>
.config_trouble_group {
  height: 60vh;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }

  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.problem_group_card_list {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  .problem_group_card {
    width: 25%;
    min-width: 400px;
  }
  .level_content {
    position: relative;
    display: flex;
    flex-flow: column;
    margin: 0 10px 10px 0;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    .show_level2_box {
      width: 100%;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 999;
    }
    .tittle {
      min-width: 180px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-family: 'PingFang SC';
      font-size: 18px;
      line-height: 28px;
      margin-right: 10px;
      cursor: pointer;
      .i_box:hover {
        display: flex !important;
        align-items: center;
      }
      span {
        margin-left: auto;
        font-size: 14px;
      }
    }
    .level2_box {
      width: 100%;
      display: flex;
      flex-flow: column;
      padding: 20px 10px 10px 10px;
      background-color: #fff;
      border-top: #d4d4d4 1px dotted;
      box-sizing: border-box;
      .level2_box_bottom {
        display: flex;
        flex-flow: column;
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        /* 滚动条整体部分 */
        &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
        .level2_content {
          width: 100%;
          display: flex;
          flex-flow: column;
          padding: 10px;
          margin-bottom: 10px;
          background-color: #fbfbfb;
          box-sizing: border-box;
          //position: relative;
          .level3_box {
            width: 100%;
            display: flex;
            flex-flow: column;
            padding: 10px;
            padding-bottom: 0px;
            position: absolute;
            top: 10px;
            right: -100%;
            transform: translateX(1px);
            //transform: translate(11px, -80px);
            border-left: #d4d4d4 1px dotted;
            background-color: #fff;
            box-sizing: border-box;
            z-index: 999 !important;
            .level3_box_bottom {
              max-height: 400px;
              overflow-y: auto;
              /* 滚动条整体部分 */
              &::-webkit-scrollbar {
                width: 3px;
                height: 9px;
              }
              /* 滚动条里面的滑块 */
              &::-webkit-scrollbar-thumb {
                background-color: rgba(144, 147, 153, 0.3);
                border-radius: 20px;
                transition: background-color 0.3s;
              }
              .level3_content {
                display: flex;
                flex-flow: column;
                padding: 10px;
                margin-bottom: 10px;
                background-color: #fbfbfb;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }
}
.level2_tittle {
  display: flex;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  span {
    font-size: 14px;
    margin-right: 10px;
  }
}
.txt_btn {
  text-align: center;
  cursor: pointer;
  margin-left: auto;
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
  span {
    font-family: 'PingFang SC';
    font-size: 12px;
  }
}
.txt {
  display: flex;
  font-size: 12px;
  margin-right: 20px;
}
.last_row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.search_input {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #448aff;
  }
}
.problem_group_level_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 70px;
  margin-left: auto;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  span {
    font-family: 'PingFang SC';
    font-size: 12px;
  }
  .i_box {
    height: 22px;
    i {
      font-size: 12px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.i1_box {
  margin-left: 10px;
  cursor: pointer;
  i {
    font-size: 16px;
    margin-right: 5px;
  }
}
.img_box {
  display: flex;
  align-items: center;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  img {
    height: 12px;
    width: 12px;
  }
}
.tittle_txt {
  max-width: 200px;
  margin-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.mini_size {
  font-size: 12px;
}
.level1_color {
  background-color: #edf4ff;
}
.level2_color {
  background-color: #fef4ec;
}
.level3_color {
  background-color: #f2fdfb;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #f79646;
}
.color3 {
  color: #7fe9db;
}
.color4 {
  color: #f53939;
}
.color5 {
  color: #afafaf;
}
</style>