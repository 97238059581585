<!-- 设置问题属性 -->
<template>
  <div
    class="set_trouble_property"
    v-loading="loading"
    element-loading-text="loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <div class="container">
      <div class="row">
        <div class="row_item">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >
          </el-checkbox>
          <span class="color3 common_size">全选所有</span>
        </div>
        <div class="row_item">
          <span class="color4" style="margin-right: 5px">已选择问题</span>
          <span class="color5">{{ selectedProblemCount }}</span>
          <span>/</span>
          <span>{{ getProblemNum }}</span>
        </div>
        <!--
          接口没有字段，暂时隐藏
          <div class="row_right">
          <div class="left">
            <span class="color1"> 最近操作时间： </span>
            <span>2022-01-14</span>
          </div>
          <span class="color1">操作人：</span>
          <span>刘思</span>
        </div> -->
      </div>
      <div
        class="problem_group_list"
        @scroll="handleScroll"
        ref="scrollview"
        v-if="list.length > 0"
      >
        <div class="problem_group_item" v-for="(item, index) in list" :key="index">
          <!-- 一级问题组标题 -->
          <div
            class="cursor_row"
            @click="list[index].isShowProblemBox = !list[index].isShowProblemBox"
          >
            <div class="i1_box">
              <i class="el-icon-caret-right" v-show="item.isShowProblemBox"></i>
              <i class="el-icon-caret-bottom" v-show="!item.isShowProblemBox"></i>
            </div>
            <span class="name_one">{{ item.groupName }}</span>
            <span class="problem_num">({{ item.questionsNumber }}题)</span>
            <div class="i_box">
              <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
            </div>
            <span class="color1 num">{{ item.weight }}</span>
            <div class="i_box">
              <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
            </div>
            <span class="color1 num">{{ item.score }}分</span>
            <div>
              <span class="color1">{{ getTipTxt(item) }}</span>
              <span class="color2">{{ item.isSetWeight }}</span>
              <span class="color1">，可分配权重</span>
              <span class="color2">{{ item.noSetWeight }}</span>
            </div>
            <div class="txt_btn">
              <div v-if="item.groups">
                <div v-if="item.groups.length > 0">
                  <span
                    v-if="item.groups[0].questionsId"
                    @click="toConfigProblem($event, item)"
                    class="color2"
                    >添加问题</span
                  >
                </div>
              </div>
              <!-- <span @click="editGroup(item)" class="color2">编辑问题组</span> -->
            </div>
          </div>
          <!-- 默认展开所有一级问题组 -->
          <div v-if="!item.isShowProblemBox">
            <!-- 问题列表 -->
            <div class="problem_list" v-if="!item.isExit">
              <!-- 问题 -->
              <div class="problem_item" v-for="(i1, i2) in item.groups" :key="i2">
                <el-checkbox
                  @change="latechange($event, i1)"
                  v-model="i1.checked1"
                  label=""
                ></el-checkbox>
                <span class="problem_name">{{ i2 + 1 }}：{{ i1.groupName }}</span>
                <span v-if="i1.isRequired" class="color7">*</span>
                <span class="color1 num">({{ getQuestionType(i1.optionTypes) }})</span>
                <div
                  class="item_icon_box"
                  @mouseenter="showOptionsBox(i1, i2)"
                  @mouseleave="hideOptionsBox(i1)"
                >
                  <div
                    class="show_options_box"
                    :class="[
                      i1.isShowOptionsBox ? '' : 'hide_options_box',
                      top ? 'show_options_box_top' : ''
                    ]"
                  >
                    <div class="options_box_top">
                      <span class="problem_name">{{ i2 + 1 }}：{{ i1.groupName }}</span>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                      </div>
                      <span class="color6 num">{{ i1.weight ? i1.weight : 0 }}</span>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                      </div>
                      <span class="color6 num">{{ i1.score ? i1.score : 0 }}分</span>
                    </div>
                    <div class="option_list">
                      <div class="option_item" v-for="(ii1, ii2) in i1.groups" :key="ii2">
                        <span class="option_name">{{ ii2 + 1 }}.{{ ii1.groupName }}</span>
                        <div class="i_box">
                          <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                        </div>
                        <span class="color6 num">{{ ii1.weight ? ii1.weight : 0 }}</span>
                        <div class="i_box">
                          <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                        </div>
                        <span class="color6 num">{{ ii1.score ? ii1.score : 0 }}分</span>
                      </div>
                    </div>
                  </div>
                  <div class="i_box">
                    <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                  </div>
                  <span class="color1 num">{{ i1.weight ? i1.weight : 0 }}</span>
                  <div class="i_box">
                    <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                  </div>
                  <span class="color1 num">{{ i1.score }}分</span>
                </div>

                <div class="span_btn_list">
                  <span class="item" @click="setProblemWeightBtn(item, i1)">设置权重</span>
                  <span class="item" @click="relatedPolicyBtn(i1)">关联政策</span>
                  <span class="item" @click="setPolicyConditionBtn(item, i1)">设置条件</span>
                </div>
              </div>
            </div>
            <div class="problem_group_level2_list" v-else>
              <div class="problem_group_level2_item" v-for="(i1, i2) in item.groups" :key="i2">
                <!-- 二级问题组标题 -->
                <div class="row">
                  <div class="border_icon"></div>
                  <span class="name_two">{{ i1.groupName }}</span>
                  <div class="i_box">
                    <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                  </div>
                  <span class="color1 num">{{ i1.weight ? i1.weight : 0 }}</span>
                  <div class="i_box">
                    <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                  </div>
                  <span class="color1 num">{{ i1.score }}分</span>
                  <div>
                    <span class="color1">{{ getTipTxt(i1) }}</span>
                    <span class="color2">{{ i1.isSetWeight }}</span>
                    <span class="color1">，可分配权重</span>
                    <span class="color2">{{ i1.noSetWeight }}</span>
                  </div>
                  <div v-if="i1.groups" class="txt_btn">
                    <div v-if="i1.groups.length > 0">
                      <span
                        v-if="i1.groups[0].questionsId"
                        @click="toConfigProblem($event, i1)"
                        class="color2"
                        >添加问题</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 问题列表 -->
                <div class="problem_list" v-if="!i1.isExit">
                  <!-- 问题 -->
                  <div class="problem_item" v-for="(ii1, ii2) in i1.groups" :key="ii2">
                    <el-checkbox
                      @change="latechange($event, ii1)"
                      v-model="ii1.checked1"
                      label=""
                    ></el-checkbox>
                    <span class="problem_name">{{ ii2 + 1 }}：{{ ii1.groupName }}</span>
                    <span v-if="ii1.isRequired" class="color7">*</span>
                    <span class="color1 num">({{ getQuestionType(ii1.optionTypes) }})</span>
                    <div
                      class="item_icon_box"
                      @mouseenter="showOptionsBox(ii1, ii2)"
                      @mouseleave="hideOptionsBox(ii1)"
                    >
                      <div
                        class="show_options_box"
                        :class="[
                          ii1.isShowOptionsBox ? '' : 'hide_options_box',
                          top ? 'show_options_box_top' : ''
                        ]"
                      >
                        <div class="options_box_top">
                          <span class="problem_name">{{ ii2 + 1 }}：{{ ii1.groupName }}</span>
                          <div class="i_box">
                            <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                          </div>
                          <span class="color6 num">{{ ii1.weight ? ii1.weight : 0 }}</span>
                          <div class="i_box">
                            <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                          </div>
                          <span class="color6 num">{{ ii1.score ? ii1.score : 0 }}分</span>
                        </div>
                        <div class="option_list">
                          <div class="option_item" v-for="(iii1, iii2) in ii1.groups" :key="iii2">
                            <span class="option_name">{{ iii2 + 1 }}.{{ iii1.groupName }}</span>
                            <div class="i_box">
                              <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                            </div>
                            <span class="color6 num">{{ iii1.weight ? iii1.weight : 0 }}</span>
                            <div class="i_box">
                              <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                            </div>
                            <span class="color6 num">{{ iii1.score ? iii1.score : 0 }}分</span>
                          </div>
                        </div>
                      </div>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                      </div>
                      <span class="color1 num">{{ ii1.weight ? ii1.weight : 0 }}</span>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                      </div>
                      <span class="color1 num">{{ ii1.score ? ii1.score : 0 }}分</span>
                    </div>

                    <div class="span_btn_list">
                      <span class="item" @click="setProblemWeightBtn(i1, ii1)">设置权重</span>
                      <span class="item" @click="relatedPolicyBtn(ii1)">关联政策</span>
                      <span class="item" @click="setPolicyConditionBtn(i1, ii1)">设置条件</span>
                    </div>
                  </div>
                </div>
                <div class="problem_group_level3_list" v-else>
                  <div class="problem_group_level3_item" v-for="(ii1, ii2) in i1.groups" :key="ii2">
                    <!-- 三级问题组标题 -->
                    <div class="row">
                      <div class="border_icon"></div>
                      <span class="name_two">{{ ii1.groupName }}</span>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                      </div>
                      <span class="color1 num">{{ ii1.weight ? ii1.weight : 0 }}</span>
                      <div class="i_box">
                        <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                      </div>
                      <span class="color1 num">{{ ii1.score ? ii1.score : 0 }}分</span>
                      <div>
                        <span class="color1">{{ getTipTxt(ii1) }}</span>
                        <span class="color2">{{ ii1.isSetWeight }}</span>
                        <span class="color1">，可分配权重</span>
                        <span class="color2">{{ ii1.noSetWeight }}</span>
                      </div>
                      <div v-if="ii1.groups" class="txt_btn">
                        <div v-if="ii1.groups.length > 0">
                          <span
                            v-if="ii1.groups[0].questionsId"
                            @click="toConfigProblem($event, ii1)"
                            class="color2"
                            >添加问题</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- 问题列表 -->
                    <div class="problem_list">
                      <!-- 问题 -->
                      <div class="problem_item" v-for="(iii1, iii2) in ii1.groups" :key="iii2">
                        <el-checkbox
                          @change="latechange($event, iii1)"
                          v-model="iii1.checked1"
                          label=""
                        ></el-checkbox>
                        <span class="option_name">{{ iii2 + 1 }}：{{ iii1.groupName }}</span>
                        <span v-if="iii1.isRequired" class="color7">*</span>
                        <span class="color1 num">({{ getQuestionType(iii1.optionTypes) }})</span>
                        <div
                          class="item_icon_box"
                          @mouseenter="showOptionsBox(iii1, iii2)"
                          @mouseleave="hideOptionsBox(iii1)"
                        >
                          <div
                            class="show_options_box"
                            :class="[
                              iii1.isShowOptionsBox ? '' : 'hide_options_box',
                              top ? 'show_options_box_top' : ''
                            ]"
                          >
                            <div class="options_box_top">
                              <span class="problem_name">{{ iii2 + 1 }}：{{ iii1.groupName }}</span>
                              <div class="i_box">
                                <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                              </div>
                              <span class="color1 num">{{ iii1.weight ? iii1.weight : 0 }}</span>
                              <div class="i_box">
                                <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                              </div>
                              <span class="color1 num">{{ iii1.score ? iii1.score : 0 }}分</span>
                            </div>
                            <div class="option_list">
                              <div
                                class="option_item"
                                v-for="(iiii1, iiii2) in iii1.groups"
                                :key="iiii2"
                              >
                                <span class="option_name"
                                  >{{ iiii2 + 1 }}.{{ iiii1.groupName }}</span
                                >
                                <div class="i_box">
                                  <img
                                    src="@/assets/image/diagnosticTools/weight-icon.png"
                                    alt=""
                                  />
                                </div>
                                <span class="color1 num">{{
                                  iiii1.weight ? iiii1.weight : 0
                                }}</span>
                                <div class="i_box">
                                  <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                                </div>
                                <span class="color1 num"
                                  >{{ iiii1.score ? iiii1.score : 0 }}分</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="i_box">
                            <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
                          </div>
                          <span class="color1 num">{{ iii1.weight ? iii1.weight : 0 }}</span>
                          <div class="i_box">
                            <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                          </div>
                          <span class="color1 num">{{ iii1.score ? iii1.score : 0 }}分</span>
                        </div>
                        <div class="span_btn_list">
                          <span class="item" @click="setProblemWeightBtn(ii1, iii1)">设置权重</span>
                          <span class="item" @click="relatedPolicyBtn(iii1)">关联政策</span>
                          <span class="item" @click="setPolicyConditionBtn(ii1, iii1)"
                            >设置条件</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_list">
        <el-button type="primary" @click="saveQuestions">保 存</el-button>
        <el-button @click="goBack" plain>返回上一级</el-button>
      </div>
    </div>
    <!-- 设置权重 -->
    <el-dialog
      title="设置权重"
      :visible.sync="setProblemWeightDialogVisible"
      :before-close="setProblemWeightDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_content">
        <div style="margin-bottom: 10px">
          <div class="row">
            <span class="name_one">{{ setWeightForm.groupName }}</span>
            <div class="i_box">
              <img src="@/assets/image/diagnosticTools/weight-icon.png" alt="" />
            </div>
            <span class="color1 num">{{ setWeightDialogWeight }}</span>
          </div>
        </div>
        <div class="row">
          <span>问题：{{ setWeightForm.problemName }}</span>
        </div>
        <div class="row">
          <span style="min-width: 42px">权重:</span>
          <div class="problem_group_weight_input">
            <el-input
              v-model="setWeightForm.weight"
              placeholder="请输入0~1以内的最多三位小数"
              @change="setProblemWeightChanged"
              oninput="value=value.replace(/^([^01]|(0(\.\d{3}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
            ></el-input>
          </div>
          <div class="row_input_right">
            <div class="i_box">
              <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
            </div>
            <span class="color2">{{ setWeightProblemScore }}分</span>
          </div>
        </div>
        <div class="row">
          <span class="color1">当前所有选项已分配权重0，可分配权重0</span>
        </div>
        <div class="option_list">
          <div class="option_item" v-for="(item, index) in setWeightForm.weightParams" :key="index">
            <div class="row">选项{{ index + 1 + '：' }} {{ item.name }}</div>
            <div class="row">
              <span style="min-width: 42px">权重:</span>
              <div class="problem_group_weight_input">
                <el-input
                  v-model="item.weight"
                  @change="setOptionWeightChanged($event, item)"
                  placeholder="请输入0~1以内的最多三位小数"
                  oninput="value=value.replace(/^([^01]|(0(\.\d{3}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
                ></el-input>
              </div>
              <div class="row_input_right">
                <div class="i_box">
                  <img src="@/assets/image/diagnosticTools/score-icon.png" alt="" />
                </div>
                <span class="color2">{{ item.score ? item.score : 0 }}分</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveProblemWeight">保存</el-button>
        <el-button @click="setProblemWeightDialogClosed">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 关联政策 -->
    <el-dialog
      title="关联政策"
      :visible.sync="relatedPolicyDialogVisible"
      :before-close="relatedPolicyDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_content">
        <div class="option_policy_list">
          <div class="option_box" v-for="(item, index) in optionList" :key="index">
            <div class="row">
              <span>选项{{ index + 1 }}</span>
              <span>：{{ item.optionData }}</span>
            </div>
            <div class="box_list">
              <div class="box_list_item" v-for="(i1, i2) in item.optionPolicyAddVos" :key="i2">
                <div class="item_box">
                  <div class="row">
                    <el-radio-group v-model="i1.isDeclare">
                      <el-radio :label="true">选择此项可申报政策</el-radio>
                      <el-radio :label="false">选择此项不可申报政策</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="row">
                    <div style="min-width: 560px">
                      <el-autocomplete
                        class="inline-input"
                        v-model="i1.declarationPolicyName"
                        :fetch-suggestions="policyNameQuerySearch"
                        placeholder="请输入"
                        :trigger-on-focus="false"
                        @input="inputChanged"
                        @select="policyNameSelect($event, i1)"
                      ></el-autocomplete>
                    </div>
                    <!-- <el-button type="primary" plain @click="addPolicyTag(i1)">添加</el-button> -->
                  </div>
                  <!-- <div class="row" v-if="i1.declarationPolicyName != ''">
                    <el-tag closable>{{ i1.declarationPolicyName }}</el-tag>
                  </div> -->
                  <div class="row" v-if="!i1.isDeclare">
                    <el-input
                      v-model="i1.noDeclarationReason"
                      type="textarea"
                      :rows="5"
                      placeholder="请输入不能申报政策的原因"
                      maxlength="50"
                      show-word-limit
                    ></el-input>
                  </div>
                </div>
                <div class="icon_box">
                  <i class="el-icon-circle-plus" @click="addRelatedPolicyItem(item)"></i>
                  <i
                    class="el-icon-remove"
                    @click="deleteRelatedPolicyItem(item, i2)"
                    v-if="i2 > 0"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveRelatedPolicy">保存</el-button>
        <el-button @click="relatedPolicyDialogClosed">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 设置条件 -->
    <el-dialog
      title="设置条件"
      :visible.sync="setPolicyConditionDialogVisible"
      :before-close="setPolicyConditionDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_content">
        <div class="row">
          <div class="dialog_label">条件类型</div>
          <el-radio-group v-model="conditionType" @change="conditionTypeChanged">
            <el-radio v-for="(item, index) in conditionTypeList" :key="index" :label="item.label">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </div>
        <!-- 分数条件 -->
        <div class="score_box" v-if="conditionType == '1'">
          <div class="row">
            <div class="dialog_label">分数条件</div>
            <el-radio-group v-model="setConditionList[0].correlationQuantity">
              <el-radio v-for="i1 in relatedAreaList" :key="i1.label" :label="i1.label">{{
                i1.name
              }}</el-radio>
            </el-radio-group>
          </div>
          <div class="num_input dialog_txt" v-if="setConditionList[0].correlationQuantity > 3">
            <el-input-number
              v-model="setConditionList[0].correlationQuantity"
              :min="4"
              :step="1"
              :max="10"
              label="请输入"
            ></el-input-number>
          </div>
          <div class="row">
            <span class="dialog_txt">
              （判断条件：在选项中，满足其中设定的条件，可获得相应的分数
            </span>
          </div>
          <div v-if="setConditionList[0].correlationQuantity != 0">
            <div class="row">
              <div class="dialog_txt">
                <el-input v-model="setConditionList[0].score" placeholder="请输入分数"></el-input>
              </div>
            </div>
            <div class="row">
              <span class="dialog_txt">
                （得分：选择分数条件后，按权重系统自动填入选项分数条件叠加对应的分数，可自行修改分数）
              </span>
            </div>
          </div>
        </div>
        <!-- 政策条件 -->
        <div class="condition_list" v-else-if="conditionType == '2'">
          <div class="condition_item" v-for="(item, index) in setConditionList" :key="index">
            <div class="dialog_label">条件{{ index + 1 }}：</div>
            <div class="condition_item_content">
              <div class="content_top">
                <!-- 当前问题 -->
                <div class="row">当前问题：{{ setConditionForm.problemName }}</div>
                <div class="row">
                  <span>当前选项：</span>
                  <el-checkbox
                    v-for="(i1, i2) in item.options"
                    :key="i2"
                    v-model="i1.isSelect"
                    @change="rawOptionSelected($event, item, i1)"
                    >{{ setConditionEcho == '0' ? i1.groupName : i1.optionData }}</el-checkbox
                  >
                  <!-- <el-checkbox-group v-model="item.rawOptionIdList">
                    <el-checkbox v-for="(i1, i2) in rawOptionList" :key="i2" :label="i1.optionId">{{
                      i1.groupName
                    }}</el-checkbox>
                  </el-checkbox-group> -->
                </div>
              </div>
              <div v-for="(i1, i2) in item.conditionVos" :key="i2">
                <div class="row">
                  <!-- 关联问题 -->
                  <el-autocomplete
                    class="inline-input"
                    v-model="i1.caption"
                    :fetch-suggestions="problemNameQuerySearch"
                    placeholder="请输入关键词、标题检索问题"
                    :trigger-on-focus="false"
                    @input="problemNameInputChanged(i1)"
                    @select="problemNameSelect($event, i1, item)"
                  ></el-autocomplete>
                  <div class="icon_box">
                    <i class="el-icon-circle-plus" @click="addRelatedProblemItem(item)"></i>
                    <i
                      class="el-icon-remove"
                      @click="deleteRelatedProblemItem(item, i2, i1)"
                      v-if="i2 > 0"
                    ></i>
                  </div>
                </div>
                <div class="row">
                  <el-checkbox
                    v-for="(ii1, ii2) in i1.options"
                    :key="ii2"
                    v-model="ii1.isSelect"
                    @change="optionsSelected($event, item, ii1)"
                    >{{ ii1.optionData }}</el-checkbox
                  >
                  <!-- <el-checkbox-group v-model="item.optionIdList">
                    <el-checkbox v-for="(ii1, ii2) in i1.options" :key="ii2" :label="ii1.id">{{
                      ii1.optionData
                    }}</el-checkbox>
                  </el-checkbox-group> -->
                </div>
              </div>
              <div class="row">
                <span>（添加关联问题，可关联多个问题）</span>
              </div>
              <div class="content_bottom">
                <div>
                  <div class="row">
                    <div class="dialog_label">可申报条件</div>
                    <el-radio-group
                      v-model="item.correlationQuantity"
                      @input="correlationQuantityChanged($event, item)"
                    >
                      <el-radio v-for="i1 in isPolicyList" :key="i1.label" :label="i1.label">{{
                        i1.name
                      }}</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div
                  style="margin-bottom: 10px"
                  class="num_input dialog_txt"
                  v-if="item.correlationQuantity > 3"
                >
                  <el-input-number
                    v-model="item.correlationQuantity"
                    :min="4"
                    :step="1"
                    :max="10"
                    label="请输入"
                  ></el-input-number>
                  <span style="margin-left: 10px">个</span>
                </div>
                <div class="row" v-show="item.correlationQuantity != 0">
                  <span class="dialog_txt">
                    （判断条件：关联的选项中，满足其中设定的条件，即可申报其中相关联的政策）
                  </span>
                </div>
                <div v-show="item.correlationQuantity != 0">
                  <div v-for="(i1, i2) in item.policyIdLists" :key="i2">
                    <div class="row dialog_txt">
                      <el-autocomplete
                        class="inline-input"
                        v-model="i1.declarationPolicyName"
                        :fetch-suggestions="policyNameQuerySearch"
                        placeholder="请输入"
                        :trigger-on-focus="false"
                        @input="inputChanged"
                        @select="policyNameSelect($event, i1, item, 'is')"
                      ></el-autocomplete>
                      <div class="icon_box">
                        <i class="el-icon-circle-plus" @click="addIsPolicyItem(item)"></i>
                        <i
                          class="el-icon-remove"
                          @click="deleteIsPolicyItem(item, i2)"
                          v-if="i2 > 0"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="dialog_label">不可申报条件</div>
                    <el-radio-group
                      v-model="item.noCorrelationQuantity"
                      @input="noCorrelationQuantityChanged($event, item)"
                    >
                      <el-radio v-for="i1 in isPolicyList" :key="i1.label" :label="i1.label">{{
                        i1.name
                      }}</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div
                  style="margin-bottom: 10px"
                  class="num_input dialog_txt"
                  v-if="item.noCorrelationQuantity > 3"
                >
                  <el-input-number
                    v-model="item.noCorrelationQuantity"
                    :min="4"
                    :step="1"
                    :max="10"
                    label="请输入"
                  ></el-input-number>
                  <span style="margin-left: 10px">个</span>
                </div>
                <div v-show="item.noCorrelationQuantity != 0">
                  <div class="row">
                    <span class="dialog_txt">
                      （判断条件：关联的选项中，满足其中设定的条件，即不可申报其中相关联的政策）
                    </span>
                  </div>
                  <div v-for="(i1, i2) in item.noPolicyIdLists" :key="i2">
                    <div class="row dialog_txt">
                      <el-autocomplete
                        class="inline-input"
                        v-model="i1.declarationPolicyName"
                        :fetch-suggestions="policyNameQuerySearch"
                        placeholder="请输入"
                        :trigger-on-focus="false"
                        @input="inputChanged"
                        @select="policyNameSelect($event, i1, item, 'no')"
                      ></el-autocomplete>
                      <div class="icon_box">
                        <i class="el-icon-circle-plus" @click="addNoPolicyItem(item)"></i>
                        <i
                          class="el-icon-remove"
                          @click="deleteNoPolicyItem(item, i2)"
                          v-if="i2 > 0"
                        ></i>
                      </div>
                    </div>
                    <div class="row dialog_txt">
                      <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入不可申报原因"
                        v-model="i1.noPolicyReasons"
                        maxlength="200"
                        show-word-limit
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="icon_box">
              <i class="el-icon-circle-plus" @click="addConditionPolicyItem(item)"></i>
              <i
                class="el-icon-remove"
                @click="deleteConditionPolicyItem(index)"
                v-if="index > 0"
              ></i>
            </div>
          </div>
        </div>
        <!-- 设置条件 -->
        <div class="area_box" v-else-if="conditionType == '3'">
          <div class="row">
            <div class="dialog_label">关联地区</div>
            <CitySelect
              ref="child"
              :province="setConditionList[0].province"
              :city="setConditionList[0].city"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </div>
          <div class="row">
            <span class="dialog_txt">
              （判断条件：地区问题中，满足其中设定的地区，即可申报其中相关联的政策）
            </span>
          </div>
        </div>
        <!-- 时间条件 -->
        <div class="time_box" v-else>
          <div class="row">
            <div class="dialog_label">时间条件</div>
            <el-radio-group v-model="setConditionList[0].correlationQuantity">
              <el-radio v-for="i1 in timeConditionList" :key="i1.label" :label="i1.label">{{
                i1.name
              }}</el-radio>
            </el-radio-group>
          </div>
          <div class="num_input" v-if="setConditionList[0].correlationQuantity > 3">
            <el-input-number
              v-model="setConditionList[0].correlationQuantity"
              :min="4"
              :step="1"
              :max="10"
              label="请输入"
            ></el-input-number>
          </div>
          <div class="row">
            <span class="dialog_txt">
              （判断条件：在选项中，满足其中设定的条件，即可申报其中相关联的政策）
            </span>
          </div>
        </div>
        <!-- 底部按钮 -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savePolicyCondition">保存</el-button>
        <el-button @click="setPolicyConditionDialogClosed">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 设置认定条件弹窗 -->
    <!-- 设置条件 -->
    <el-dialog
      title="设置认定条件"
      :visible.sync="conditionDialogVisible"
      :before-close="setManyConditionDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_content">
        <div class="row">
          <div class="dialog_label">条件类型</div>
          <el-radio-group v-model="questionnaireConditions[0].type">
            <el-radio label="1">分数条件</el-radio>
          </el-radio-group>
        </div>
        <div class="condition_list">
          <div class="condition_item" v-for="(item, index) in questionnaireConditions" :key="index">
            <div class="dialog_label">认定条件{{ index + 1 }}</div>
            <div class="condition_item_content">
              <div class="condition_item">
                <div class="dialog_label">条件1</div>
                <div class="item_content">
                  <el-radio style="line-height: 40px" v-model="question" label="1"
                    >全部题目</el-radio
                  >
                  <!-- <el-radio-group v-model="">
                    <el-radio label="全部题目"></el-radio>
                  </el-radio-group> -->
                  <el-radio-group v-model="item.comparator">
                    <el-radio v-for="el in comparatorList" :key="el.label" :label="el.label">{{
                      el.name
                    }}</el-radio>
                  </el-radio-group>
                  <div class="row">
                    <div class="mini_input">
                      <el-input v-model="item.sort" placeholder="请输入分数"></el-input>
                    </div>
                    <div class="input_txt">分</div>
                  </div>
                  <div class="row_span">（条件：在问卷中，满足其中设定的条件，可申报对应政策）</div>
                </div>
              </div>
              <div class="condition_item" v-for="(i1, i2) in item.questionsName" :key="i2">
                <div class="dialog_label">条件{{ i2 + 2 }}</div>
                <!-- 关联问题 -->
                <el-autocomplete
                  class="inline-input"
                  v-model="item.questionsName[i2]"
                  :fetch-suggestions="problemNameQuerySearchTwo"
                  placeholder="请输入关键词、标题检索问题"
                  :trigger-on-focus="false"
                  @input="problemNameInputChangedTwo()"
                  @select="problemNameSelectTwo($event, i1, i2, item)"
                ></el-autocomplete>
                <div class="icon_box">
                  <i class="el-icon-circle-plus" @click="addRelatedProblemItemTwo(item)"></i>
                  <i
                    class="el-icon-remove"
                    @click="deleteRelatedProblemItemTwo(item, i2)"
                    v-if="i2 > 0"
                  ></i>
                </div>
              </div>
              <div class="condition_item">
                <div class="dialog_label">可申报条件</div>
                <div class="item_content">
                  <el-radio-group v-model="item.satisfyConditions">
                    <el-radio :label="0">同时满足</el-radio>
                    <el-radio :label="1">满足其1</el-radio>
                    <el-radio :label="2">满足其2</el-radio>
                    <el-radio :label="3" v-if="item.questionsName.length + 1 > 2">自定义</el-radio>
                  </el-radio-group>
                  <div class="num_input row" v-if="item.satisfyConditions > 2">
                    <el-input-number
                      v-model="item.satisfyConditions"
                      :min="3"
                      :step="1"
                      :max="item.questionsName.length + 1"
                      label="请输入"
                    ></el-input-number>
                    <span style="margin-left: 10px">个</span>
                  </div>
                  <div class="row_span row">
                    （判断条件：关联的选项中，满足其中设定的条件，即可申报其中相关联的政策）
                  </div>
                  <div v-for="(i1, i2) in item.relatedPolicyList" :key="i2">
                    <div class="row">
                      <el-autocomplete
                        class="inline-input"
                        v-model="item.relatedPolicyList[i2]"
                        :fetch-suggestions="policyNameQuerySearchTwo"
                        placeholder="请输入"
                        :trigger-on-focus="false"
                        @input="inputChangedTwo"
                        @select="policyNameSelectTwo($event, i1, i2, item, 'is')"
                      ></el-autocomplete>
                      <div class="icon_box">
                        <i class="el-icon-circle-plus" @click="addIsPolicyItemTwo(item)"></i>
                        <i
                          class="el-icon-remove"
                          @click="deleteIsPolicyItemTwo(item, i2)"
                          v-if="i2 > 0"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveManyCondition">保存</el-button>
        <el-button @click="setManyConditionDialogClosed">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectQuestions,
  updateWeight,
  selectOptionPolicy,
  saveOptionPolicy,
  addConditions,
  selectConditions,
  updateOptionPolicy,
  selectQuestionnaireConditions,
  addQuestionnaireConditions
} from '@/api/diagnoseTool.js'
import { policyList } from '@/api/policy.js'
import CitySelect from '@/components/CitySelect.vue'
import { problemList, selectProblemById } from '@/api/problem.js'
import { accMul } from '@/utils/function.js'

//查询政策的参数
const defaultPolicyQueryInfo = Object.freeze({
  city: '',
  country: '',
  declarationEndTime: '',
  declarationStartTime: '',
  industryCategory: [],
  isDeclaration: '',
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: '',
  releaseUnit: ''
})
//查询问题的参数
const defaultProblemQueryInfo = Object.freeze({
  questionnaireId: null, //工具id
  isCorrelationCondition: true, //
  isGroup: false, //是否在问题组中展示
  city: '',
  country: '',
  evaluationType: '', //评估类型(1:企业数智化转型诊断评估,2:企业数智化转型政策申报)
  industryCategory: [], //行业类别集合
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: '',
  questionsType: '' //问题类型：企业基本信息、数智化转型战略、数智化转型基础、数智化转型应用、数智化应用成效、企业产品与服务、其它、战略规划、组织规划
})

export default {
  name: 'setTroubleProperty',
  components: { CitySelect },
  props: {
    id: {}, //工具id
    new: {}, //控制调查询接口
    active: {
      type: Number
    },
    conditionDialogVisible: {
      // 设置认定条件弹窗
      type: Boolean
    }
  },
  watch: {
    new: {
      //父组件改变了new的值 重新调用查询一览接口
      handler() {
        if (this.new != 0) {
          this.search()
        }
      },
      immediate: true
    },
    active: {
      handler() {
        if (this.active == 3) {
          this.search()
        }
      },
      immediate: true
    },
    conditionDialogVisible: {
      handler(val) {
        if (this.conditionDialogVisible === true) {
          // 查询认定条件
          this.searchQuestionnaireConditions()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      clientHeight: null,
      containerClientHeight: null,
      scrollHeight: null,

      question: '1', // 认定条件1
      // 认定条件列表
      questionnaireConditions: [
        {
          comparator: '1', //比较符（1、大于等于；2、小于等于；3、大于；4、小于）
          id: 0,
          questionnaireId: this.id, // 问卷id
          questionsId: '', // 问题id集合（string类型）
          questionsIds: [], // 问题id集合
          questionsName: [''], // 问题名称集合
          relatedPolicyId: '', // 关联政策id集合（string类型）
          relatedPolicyIds: [], // 关联政策id集合
          relatedPolicyList: [''], // 关联政策集合
          satisfyConditions: 0, // 满足可申报条件个数
          sort: 60, // 分数
          type: '1' // 类型（1、分数条件）
        }
      ],
      // 比较条件列表
      comparatorList: [
        {
          label: '1',
          name: '大于等于'
        },
        {
          label: '2',
          name: '小于等于'
        },
        {
          label: '3',
          name: '大于'
        },
        {
          label: '4',
          name: '小于'
        }
      ],
      loading: false, //遮罩层
      problemCount: 0, //问题个数
      selectedProblemCount: 0, //选中的问题个数
      top: true, //控制展示选项box往上位移
      isShowOptionsBox: false, //是否展示选项
      ids: [], //选中的问题组与问题关联id集合
      optionIds: [], //选中的问题与选项关联id集合
      idsList: [], //选中的问题集合
      checkAll: false, //显示全选图标
      problemNum: 0, //所有问题数量
      isIndeterminate: false, //显示半选图标
      setProblemWeightDialogVisible: false, //设置权重弹窗
      relatedPolicyDialogVisible: false, //关联政策弹窗
      setPolicyConditionDialogVisible: false, //设置条件弹窗
      level1Score: null, //一级问题组分数
      setWeightForm: {
        id: 0, //关联id
        weight: null, //权重
        groupName: '', //问题组名
        problemName: '', //问题名
        weightParams: [] // 选项数据
      },
      setWeightDialogWeight: null, //设置权重弹窗中问题组的权重
      setWeightDialogScore: 0, //设置权重弹窗中问题组的分数
      setWeightProblemScore: 0, //设置权重弹窗中问题的分数
      policyQueryInfo: { ...defaultPolicyQueryInfo }, //查询政策参数
      problemQueryInfo: { ...defaultProblemQueryInfo }, //查询政策参数
      //选项关联政策集合
      optionList: [],
      policyNameList: [], //查询到的政策
      optionPolicyEcho: '0', //关联政策弹窗  0是新增，1是编辑
      setConditionForm: {
        problemName: '' //当前问题名称
      },
      setConditionEcho: '0', //0是新增，1是编辑
      setConditionQuestionId: null, //当前问题id
      //条件类型列表
      conditionTypeList: [
        {
          label: '1',
          name: '分数条件'
        },
        {
          label: '2',
          name: '政策条件'
        },
        {
          label: '3',
          name: '地区条件'
        },
        {
          label: '4',
          name: '时间条件'
        }
      ],

      problemNameList: [], //查询到的问题名称集合
      //关联地区 单选框
      relatedAreaList: [
        {
          label: 0,
          name: '无'
        },
        {
          label: 1,
          name: '选择一个以上'
        },
        {
          label: 2,
          name: '选择两个以上'
        },
        {
          label: 3,
          name: '选择三个以上'
        },
        {
          label: 4,
          name: '自定义'
        }
      ],
      //申报政策条件 单选框
      isPolicyList: [
        {
          label: 0,
          name: '无'
        },
        {
          label: 1,
          name: '任意一个选项'
        },
        {
          label: 2,
          name: '任意两个选项'
        },
        {
          label: 3,
          name: '任意三个选项'
        },
        {
          label: 4,
          name: '自定义'
        }
      ],
      //时间条件 单选框
      timeConditionList: [
        {
          label: 1,
          name: '满1年以上'
        },
        {
          label: 2,
          name: '满2年以上'
        },
        {
          label: 3,
          name: '满3年以上'
        },
        {
          label: 4,
          name: '自定义'
        }
      ],
      conditionType: '2', //选中的条件类型
      //设置条件的传参
      setConditionList: [
        {
          city: '', //市
          conditionType: '2', //条件类型(1->分数条件；2->政策条件；3->地区条件；4->时间条件)
          correlationQuantity: 0, //可申报政策关联数量/分数条件的关联数量/时间条件的年份
          id: null, //主键（有则编辑，无则新增）
          noCorrelationQuantity: 0, //不可申报政策关联数量
          noPolicyId: '', //不可申报的政策id集合(,号隔开)
          noPolicyIdList: [], //不可申报的政策id集合
          noPolicyReason: '', //不可申报的政策理由集合(£号隔开)
          noPolicyReasonList: [], //不可申报的政策理由集合
          optionIdList: [], //关联选项id集合
          optionIds: '', //关联选项id集合(,号隔开)
          policyId: '', //可申报的政策id集合(,号隔开)
          policyIdList: [], //可申报的政策id集合
          province: '', //省
          questionId: null, //当前问题id
          questionnaireId: null, //问卷id
          rawOptionId: '', //当前选项id集合(,号隔开)
          rawOptionIdList: [], //当前选项id集合
          options: [
            {
              id: null,
              optionData: '',
              isSelect: false
            }
          ], //当前的问题集合 非传字段
          conditionVos: [
            {
              caption: '',
              id: null,
              options: []
            }
          ], //关联的问题集合 非传字段
          policyIdLists: [
            {
              declarationPolicyName: '', //政策名称
              id: null //政策id
            }
          ], //关联的可申报政策集合 非传字段
          noPolicyIdLists: [
            {
              declarationPolicyName: '', //政策名称
              id: null, //政策id
              noPolicyReasons: '' //不可申报的原因
            }
          ], //关联的不可申报政策集合 非传字段
          score: null //分数
        }
      ],
      setConditionQueryInfo: {
        questionnaireId: null, //工具id
        conditionType: '2',
        questionId: null //当前问题id
      },
      // 问题类型
      questionType: [
        {
          type: '0',
          label: '单选题'
        },
        {
          type: '1',
          label: '多选题'
        },
        {
          type: '2',
          label: '填写题'
        },
        {
          type: '3',
          label: '地区'
        },
        {
          type: '4',
          label: '单个时间（年月日）'
        },
        {
          type: '5',
          label: '时间段（年月日）'
        },
        {
          type: '8',
          label: '行业'
        }
      ],
      list: [] //列表
    }
  },
  created() {
    this.problemQueryInfo.questionnaireId = this.id
  },
  computed: {
    getProblemNum() {
      let num = 0
      this.list.forEach((item) => {
        num = num + item.questionsNumber
      })
      return num
    },
    getQuestionType() {
      return function (val) {
        let str = ''
        this.questionType.forEach((item) => {
          if (item.type === val) {
            str = item.label
          }
        })
        return str
      }
    },
    getTipTxt() {
      return function (item) {
        let str = ''
        if (item.groups && item.groups.length > 0) {
          if (!item.groups[0].questionsId) {
            str = '当前问题组已分配权重'
          } else {
            str = '当前所有问题已分配权重'
          }
        } else {
          str = '当前所有问题已分配权重'
        }
        return str
      }
    }
  },
  mounted() {
    // this.clientHeight = document.body.clientHeight
    // this.containerClientHeight = document.getElementsByClassName('set_trouble_property')
    // console.log(this.containerClientHeight)
  },
  beforeDestroy() {},
  filters: {},
  methods: {
    //查询 问卷一览
    async search() {
      this.loading = true
      const { data: res } = await selectQuestions({
        questionnaireId: this.id
      })
      if (res.resultCode === 200) {
        this.loading = false
        this.list = res.data
        this.handleCheckAllChange(false)
      } else {
        this.$message.error('获取问卷信息失败!')
        this.loading = false
      }
    },
    // 查询认定条件
    async searchQuestionnaireConditions() {
      const { data: res } = await selectQuestionnaireConditions({ questionnaireId: this.id })
      if (res.resultCode === 200) {
        if (res.data.length > 0) {
          this.questionnaireConditions = res.data
        } else {
          this.questionnaireConditions = [
            {
              comparator: '1', //比较符（1、大于等于；2、小于等于；3、大于；4、小于）
              id: 0,
              questionnaireId: this.id, // 问卷id
              questionsId: '', // 问题id集合（string类型）
              questionsIds: [], // 问题id集合
              questionsName: [''], // 问题名称集合
              relatedPolicyId: '', // 关联政策id集合（string类型）
              relatedPolicyIds: [], // 关联政策id集合
              relatedPolicyList: [''], // 关联政策集合
              satisfyConditions: 0, // 满足可申报条件个数
              sort: 60, // 分数
              type: '1' // 类型（1、分数条件）
            }
          ]
        }
      } else {
        this.$message.error('获取认定条件详情失败!')
      }
    },
    // 关闭弹窗
    setManyConditionDialogClosed() {
      this.$emit('changeVisible', false)
      // this.conditionDialogVisible = false
    },
    //查询问题名称 设置认定条件弹窗
    async problemNameQuerySearchTwo(str, cb) {
      if (str != '') {
        this.problemQueryInfo.queryConditions = str
        const { data: res } = await problemList(this.problemQueryInfo)
        if (res.resultCode === 200) {
          res.data.list.forEach((item) => {
            this.problemNameList.push({
              id: item.id,
              value: item.caption
            })
          })
          cb(this.problemNameList)
        } else {
          this.$message.error('查询失败!')
        }
      }
    },
    //输入值变化时重置problemNameList 设置认定条件弹窗
    problemNameInputChangedTwo() {
      this.problemNameList = []
    },
    //选择问题名称 设置认定条件弹窗
    async problemNameSelectTwo(val, i1, i2, item) {
      this.problemQueryInfo.queryConditions = val.value
      const { data: res } = await problemList(this.problemQueryInfo)
      if (res.resultCode === 200) {
        const data = res.data.list[0]
        i1 = data.caption
        item.questionsIds[i2] = data.id
        this.problemNameList = []
      }
    },
    //添加关联问题输入框 设置认定条件弹窗
    addRelatedProblemItemTwo(item) {
      item.questionsName.push('')
      item.questionsIds.push(0)
      // {
      //   caption: '',
      //   id: null,
      //   options: []
      // }
    },
    //删除关联问题输入框 设置认定条件弹窗
    deleteRelatedProblemItemTwo(item, i2) {
      item.questionsName.splice(i2, 1)
      item.questionsIds.splice(i2, 1)
    },

    //添加选项关联政策item 设置认定条件弹窗
    addIsPolicyItemTwo(item) {
      item.relatedPolicyList.push('')
      item.relatedPolicyIds.push(0)
    },
    //删除选项关联政策item 设置认定条件弹窗
    deleteIsPolicyItemTwo(item, i2) {
      item.relatedPolicyList.splice(i2, 1)
      item.relatedPolicyIds.splice(i2, 1)
    },
    //输入值变化时重置policyNameList 设置认定条件弹窗
    inputChangedTwo() {
      this.policyNameList = []
    },
    //选择政策名称 设置认定条件弹窗
    async policyNameSelectTwo(val, i1, i2, item, value) {
      //val是输入的值，i1是item的子集，value区分是否可申报政策
      this.policyQueryInfo.queryConditions = val.value
      const { data: res } = await policyList(this.policyQueryInfo)
      if (res.resultCode === 200) {
        const data = res.data.list[0]
        i1 = data.declarationPolicyName
        this.policyNameList = []
        item.relatedPolicyIds[i2] = data.id
      }
    },
    //查询政策名称 设置认定条件弹窗
    async policyNameQuerySearchTwo(str, cb) {
      if (str != '') {
        this.policyQueryInfo.queryConditions = str
        const { data: res } = await policyList(this.policyQueryInfo)
        if (res.resultCode === 200) {
          res.data.list.forEach((item) => {
            this.policyNameList.push({
              id: item.id,
              value: item.declarationPolicyName
            })
          })
          cb(this.policyNameList)
        } else {
          this.$message.error('查询失败!')
        }
      }
    },
    // 保存按钮
    async saveManyCondition() {
      const { data: res } = await addQuestionnaireConditions(this.questionnaireConditions)
      if (res.resultCode === 200) {
        this.$message.success('保存成功!')
        this.$emit('changeVisible', false)
      } else {
        this.$message.error('保存失败!')
      }
    },
    // 计算分数
    getScore(num1, num2) {
      let score = accMul(Number(num1), Number(num2))
      return score
    },
    //返回省 设置条件
    searchProvince(val) {
      this.setConditionList[0].province = val
    },
    //返回城市 设置条件
    searchCity(val) {
      this.setConditionList[0].city = val
    },
    //问题组盒子滚动时
    handleScroll() {
      const container = document.getElementsByClassName('problem_group_list')
      const { scrollTop } = container[0]
      // console.log(scrollTop)
      this.top = true
      // if (scrollTop > 600) {
      //   // do something
      //   this.top = true
      // } else if (scrollTop < 200) {
      //   this.top = false
      // }
    },
    //显示选项box
    showOptionsBox(item, index) {
      // console.log(index)
      // const top = document.getElementsByClassName('problem_item')[index].offsetTop
      // console.log(top)

      // 获取元素距离浏览器位置
      // let num = this.getProblemNum - 1
      // const elProblem = document.getElementsByClassName('problem_item')
      // let move1 = elProblem[0].getBoundingClientRect().top
      item.isShowOptionsBox = true
      // this.$refs.scrollview.scrollTop = this.$refs.scrollview.scrollHeight
    },
    //隐藏选项box
    hideOptionsBox(item) {
      item.isShowOptionsBox = false
    },
    //全选框
    handleCheckAllChange(bol) {
      // bol返回的是布尔值，选中状态为true
      if (bol) {
        this.list.forEach((item) => {
          if (item.groups) {
            //一级问题组是否有子级
            if (item.groups.length > 0) {
              //一级问题组是否有子级
              item.groups.forEach((el) => {
                if (el.questionsId) {
                  //如果有questionsId则 el 是是问题，否则是二级问题组
                  this.ids.push(el.questionsOptionId)
                  this.$set(el, 'checked1', true)
                  if (el.groups) {
                    //问题是否有选项
                    el.groups.forEach((i) => {
                      if (i.questionsOptionId) {
                        this.optionIds.push(i.questionsOptionId)
                      }
                    })
                  }
                } else {
                  if (el.groups) {
                    if (el.groups.length > 0) {
                      //二级问题组是否有子级
                      el.groups.forEach((i) => {
                        if (i.questionsId) {
                          //如果有questionsId则 i 是是问题，否则是三级问题组
                          this.ids.push(i.questionsOptionId)
                          this.$set(i, 'checked1', true)
                          if (i.groups) {
                            //问题是否有选项
                            i.groups.forEach((ii) => {
                              if (ii.questionsOptionId) {
                                this.optionIds.push(ii.questionsOptionId)
                              }
                            })
                          }
                        } else {
                          if (i.groups) {
                            if (i.groups.length > 0) {
                              //三级问题组是否有子级
                              i.groups.forEach((ii) => {
                                if (ii.questionsId) {
                                  //如果有questionsId则ii是是问题，否则没有配置问题
                                  this.ids.push(ii.questionsOptionId)
                                  this.$set(ii, 'checked1', true)
                                  if (ii.groups) {
                                    //问题是否有选项
                                    ii.groups.forEach((iii) => {
                                      if (iii.questionsOptionId) {
                                        this.optionIds.push(iii.questionsOptionId)
                                      }
                                    })
                                  }
                                } else {
                                  //console.log('请给三级问题组--' + `${i.groupName}` + '配置问题!')
                                  // this.$message.warning(
                                  //   '请给三级问题组--' + `${i.groupName}` + '配置问题!'
                                  // )
                                }
                              })
                            }
                          } else {
                            //console.log('请给二级问题组--' + `${i.groupName}` + '配置问题!')
                          }
                        }
                      })
                    }
                  }
                }
              })
            }
          }
        })
      } else {
        this.ids = []
        this.optionIds = []
        this.idsList = []
        this.list.forEach((item) => {
          if (item.groups) {
            //一级问题组是否有子级
            if (item.groups.length > 0) {
              //一级问题组是否有子级
              item.groups.forEach((el) => {
                if (el.questionsId) {
                  //如果有questionsId则el是是问题，否则是二级问题组
                  this.$set(el, 'checked1', false)
                } else {
                  if (el.groups) {
                    if (el.groups.length > 0) {
                      //二级问题组是否有子级
                      el.groups.forEach((i) => {
                        if (i.questionsId) {
                          //如果有questionsId则i是是问题，否则是三级问题组
                          this.$set(i, 'checked1', false)
                        } else {
                          if (i.groups) {
                            if (i.groups.length > 0) {
                              i.groups.forEach((ii) => {
                                if (ii.questionsId) {
                                  //如果有questionsId则ii是是问题，否则问题组没有配置问题
                                  this.$set(ii, 'checked1', false)
                                }
                              })
                            }
                          }
                        }
                      })
                    }
                  }
                }
              })
            }
          }
        })
      }
      this.selectedProblemCount = this.ids.length
      let obj = {
        ids: this.ids,
        optionIds: this.optionIds
      }
      this.$emit('getIds', obj)
      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, item) {
      // bol返回的是布尔值，选中状态为true
      //获取点击的问题的id在ids中的索引，便于删除该id
      const index = this.ids.indexOf(item.questionsOptionId)
      const i2 = this.ids.indexOf(item.questionsOptionId)
      if (bol) {
        //勾选or取消勾选问题
        this.ids.push(item.questionsOptionId)
        this.idsList.push(item)
        if (item.groups) {
          item.groups.forEach((i) => {
            if (i.questionsOptionId) {
              this.optionIds.push(i.questionsOptionId)
            }
          })
        }
        if (this.ids.length == this.getProblemNum) {
          //选中的问题数是否与问题总数相等
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
        this.optionIds.splice(i2, 1)
        if (this.ids.length < 1) {
          //是否还有选中的问题
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
      this.selectedProblemCount = this.ids.length
      let obj = {
        ids: this.ids,
        optionIds: this.optionIds
      }
      this.$emit('getIds', obj)
    },
    //添加选项关联政策item
    addRelatedPolicyItem(item) {
      item.optionPolicyAddVos.push({
        declarationId: null, //政策id
        declarationPolicyName: '', //政策名称
        id: null, //选项政策的关联id
        isDeclare: true, //申报状态（true:可申报/false:不可申报）
        noDeclarationReason: '', //不可申报理由
        questionnaireId: this.id //问卷id
      })
    },
    //删除选项关联政策item
    deleteRelatedPolicyItem(item, i2) {
      if (!item.deleteIds) {
        //非空处理
        item.deleteIds = []
      }
      if (item.optionPolicyAddVos[i2].id) {
        //删除的是有关联id的项·
        item.deleteIds.push(item.optionPolicyAddVos[i2].id)
      }
      item.optionPolicyAddVos.splice(i2, 1)
    },
    //输入值变化时重置policyNameList
    inputChanged() {
      this.policyNameList = []
    },
    //选择政策名称
    async policyNameSelect(val, i1, item, value) {
      //val是输入的值，i1是item的子集，value区分是否可申报政策
      this.policyQueryInfo.queryConditions = val.value
      const { data: res } = await policyList(this.policyQueryInfo)
      if (res.resultCode === 200) {
        i1.declarationPolicyName = res.data.list[0].declarationPolicyName
        this.policyNameList = []
        if (typeof item == 'object') {
          //设置条件弹窗里的关联政策
          console.log(value)
          i1.id = res.data.list[0].id
          if (value == 'is') {
            //可申报条件
            item.policyIdList.push(i1.id)
          } else {
            item.noPolicyIdList.push(i1.id)
          }
        } else {
          i1.declarationId = res.data.list[0].id
        }
      }
    },
    //查询政策名称
    async policyNameQuerySearch(str, cb) {
      if (str != '') {
        this.policyQueryInfo.queryConditions = str
        const { data: res } = await policyList(this.policyQueryInfo)
        if (res.resultCode === 200) {
          res.data.list.forEach((item) => {
            this.policyNameList.push({
              id: item.id,
              value: item.declarationPolicyName
            })
          })
          cb(this.policyNameList)
        } else {
          this.$message.error('查询失败!')
        }
      }
    },
    //打开关联政策弹窗
    async relatedPolicyBtn(item) {
      this.optionList = []
      const { data: res } = await selectOptionPolicy({
        questionnaireId: this.id,
        questionsId: item.questionsId
      })
      if (res.resultCode === 200) {
        res.data.forEach((el) => {
          el.optionPolicyAddVos.forEach((i) => {
            i.questionnaireId = this.id
            if (i.id) {
              //如果有id--选项政策的关联id则此次打开弹窗为编辑
              this.optionPolicyEcho = '1'
            }
          })
        })
        if (this.optionPolicyEcho == '0') {
          //新增
          item.groups.forEach((el) => {
            let optionObj = {
              deleteIds: [],
              optionData: el.groupName,
              optionId: el.optionId,
              optionPolicyAddVos: [
                {
                  declarationId: null,
                  declarationPolicyName: '',
                  id: null,
                  isDeclare: true,
                  noDeclarationReason: '',
                  questionnaireId: this.id
                }
              ]
            }
            this.optionList.push(optionObj)
          })
        } else {
          //编辑
          this.optionList = res.data
        }
      }
      this.relatedPolicyDialogVisible = true
    },
    //关联政策弹窗 保存
    async saveRelatedPolicy() {
      if (this.optionPolicyEcho == '0') {
        let arr = []
        this.optionList.map((item) => {
          item.optionPolicyAddVos.map((el) => {
            if (el.declarationId != null) {
              arr.push(item)
            }
          })
        })
        const { data: res } = await saveOptionPolicy(arr)
        if (res.resultCode === 200) {
          this.search()
          this.relatedPolicyDialogVisible = false
          this.$message.success('关联政策成功!')
        } else {
          this.$message.error('关联政策失败!')
        }
      } else {
        const { data: res } = await updateOptionPolicy(this.optionList)
        if (res.resultCode === 200) {
          this.search()
          this.relatedPolicyDialogVisible = false
          this.$message.success('修改成功!')
        } else {
          this.$message.error('修改失败!')
        }
      }
    },
    //关联政策弹窗关闭
    relatedPolicyDialogClosed() {
      this.relatedPolicyDialogVisible = false
    },
    //当前选项选择框
    rawOptionSelected(bol, item, i1) {
      let index = 0
      if (this.setConditionEcho == '0') {
        //为新增时
        index = item.rawOptionIdList.indexOf(i1.optionId)
      } else {
        //为编辑时
        index = item.rawOptionIdList.indexOf(i1.id)
      }
      if (bol) {
        if (index === -1) {
          if (this.setConditionEcho == '0') {
            item.rawOptionIdList.push(i1.optionId) //放入选项id
          } else {
            item.rawOptionIdList.push(i1.id)
          }
        }
      } else {
        if (index != -1) {
          item.rawOptionIdList.splice(index, 1)
        }
      }
    },
    // 选中关联选项选择框
    optionsSelected(bol, item, ii1) {
      //无需考虑是否为新增
      const index = item.optionIdList.indexOf(ii1.id)
      if (bol) {
        item.optionIdList.push(ii1.id)
      } else {
        if (index != -1) {
          item.optionIdList.splice(index, 1)
        }
      }
    },
    //条件类型单选框切换
    conditionTypeChanged(val) {
      this.setConditionQueryInfo.conditionType = val
      this.selectConditions()
      // if (val != '1') {
      //   this.setConditionList[0].score = ''
      // } else if (val != '2') {
      //   this.relatedProblemList = [
      //     {
      //       value: '',
      //       id: null,
      //       optionList: []
      //     }
      //   ]
      //   this.relatedIsPolicyList = [
      //     {
      //       declarationPolicyName: '',
      //       declarationId: null
      //     }
      //   ]
      //   this.relatedNoPolicyList = [
      //     {
      //       declarationPolicyName: '',
      //       declarationId: null
      //     }
      //   ]
      //   this.setConditionList.splice(1, this.setConditionList.length - 1)
      //   this.setConditionList.forEach((item) => {
      //     item.noPolicyIdList = []
      //     item.noPolicyReasonList = []
      //     item.optionIdList = []
      //     item.policyIdList = []
      //     item.rawOptionIdList = []
      //   })
      // } else if (val != '3') {
      //   this.setConditionList[0].province = ''
      //   this.setConditionList[0].city = ''
      // }
    },
    //添加政策条件item
    addConditionPolicyItem(item) {
      this.setConditionList.push({
        city: '', //市
        conditionType: '2', //条件类型(1->分数条件；2->政策条件；3->地区条件；4->时间条件)
        correlationQuantity: 0, //可申报政策关联数量/分数条件的关联数量/时间条件的年份
        id: null, //主键（有则编辑，无则新增）
        noCorrelationQuantity: 0, //不可申报政策关联数量
        noPolicyId: '', //不可申报的政策id集合(,号隔开)
        noPolicyIdList: [], //不可申报的政策id集合
        noPolicyReason: '', //不可申报的政策理由集合(£号隔开)
        noPolicyReasonList: [], //不可申报的政策理由集合
        optionIdList: [], //关联选项id集合
        optionIds: '', //关联选项id集合(,号隔开)
        policyId: '', //可申报的政策id集合(,号隔开)
        policyIdList: [], //可申报的政策id集合
        province: '', //省
        questionId: null, //当前问题id
        questionnaireId: null, //问卷id
        rawOptionId: '', //当前选项id集合(,号隔开)
        rawOptionIdList: [], //当前选项id集合
        options: [
          {
            id: null,
            optionData: '',
            isSelect: false
          }
        ], //当前的问题集合 非传字段
        conditionVos: [
          {
            caption: '',
            id: null,
            options: []
          }
        ], //关联的问题集合 非传字段
        policyIdLists: [
          {
            declarationPolicyName: '', //政策名称
            id: null //政策id
          }
        ], //关联的可申报政策集合 非传字段
        noPolicyIdLists: [
          {
            declarationPolicyName: '', //政策名称
            id: null, //政策id
            noPolicyReasons: '' //不可申报的原因
          }
        ], //关联的不可申报政策集合 非传字段
        score: null //分数
      })
    },
    //删除政策条件item
    deleteConditionPolicyItem(index) {
      this.setConditionList.splice(index, 1)
    },
    //添加关联问题输入框
    addRelatedProblemItem(item) {
      item.conditionVos.push({
        caption: '',
        id: null,
        options: []
      })
    },
    //删除关联问题输入框
    deleteRelatedProblemItem(item, index, i1) {
      item.conditionVos.splice(index, 1)
      let arr1 = []
      i1.options.forEach((el) => {
        arr1.push(el.id)
      })
      let arr = []
      item.optionIdList.map((el) => {
        console.log(el)
        const ii2 = arr1.indexOf(el)
        if (ii2 === -1) {
          arr.push(el)
        }
      })
      item.optionIdList = arr
    },
    // 不可申报政策条数数量变化
    noCorrelationQuantityChanged(val, item) {
      if (val != 0) {
        item.noPolicyIdLists = [
          {
            declarationPolicyName: '', //政策名称
            id: null, //政策id
            noPolicyReasons: '' //不可申报的原因
          }
        ]
      } else {
        item.noPolicyIdLists = []
      }
    },
    // 可申报政策条数数量变化
    correlationQuantityChanged(val, item) {
      if (val != 0) {
        item.policyIdLists = [
          {
            declarationPolicyName: '', //政策名称
            id: null //政策id
          }
        ]
      } else {
        item.policyIdLists = []
      }
    },
    //添加可申报政策输入框
    addIsPolicyItem(item) {
      item.policyIdLists.push({
        declarationPolicyName: '',
        id: null
      })
    },
    //删除可申报政策输入框
    deleteIsPolicyItem(item, index) {
      item.policyIdLists.splice(index, 1)
    },
    //添加不可申报政策输入框
    addNoPolicyItem(item) {
      item.noPolicyIdLists.push({
        declarationPolicyName: '',
        id: null,
        noPolicyReasons: '' //不可申报的原因
      })
    },
    //删除不可申报政策输入框
    deleteNoPolicyItem(item, index) {
      item.noPolicyIdLists.splice(index, 1)
    },
    //输入值变化时重置problemNameList
    problemNameInputChanged(i1) {
      this.problemNameList = []
      i1.options = []
    },
    //选择问题名称
    async problemNameSelect(val, i1, item) {
      this.problemQueryInfo.queryConditions = val.value
      const { data: res } = await problemList(this.problemQueryInfo)
      if (res.resultCode === 200) {
        i1.value = res.data.list[0].caption
        i1.id = res.data.list[0].id
        this.problemNameList = []
        const { data: result } = await selectProblemById({ questionsId: i1.id })
        if (result.resultCode === 200) {
          i1.options = result.data.optionList
        }
      }
    },
    //查询问题名称
    async problemNameQuerySearch(str, cb) {
      if (str != '') {
        this.problemQueryInfo.queryConditions = str
        const { data: res } = await problemList(this.problemQueryInfo)
        if (res.resultCode === 200) {
          res.data.list.forEach((item) => {
            this.problemNameList.push({
              id: item.id,
              value: item.caption
            })
          })
          cb(this.problemNameList)
        } else {
          this.$message.error('查询失败!')
        }
      }
    },
    //打开设置条件弹窗
    async setPolicyConditionBtn(item, i1) {
      //item为问题组，i1为问题
      this.setConditionQuestionId = i1.questionsId //获取当前问题id
      this.setConditionForm.problemName = i1.groupName //获取当前问题名称
      this.setConditionQueryInfo.questionId = i1.questionsId //获取当前问题id
      this.setConditionQueryInfo.questionnaireId = this.id //获取当前工具id
      this.problemQueryInfo.questionnaireId = this.id //获取当前工具id
      this.selectConditions().then(() => {
        if (this.setConditionEcho == '0') {
          //经过查询后确定此次弹窗为新增时(第一次打开)
          this.setConditionList[0].options = i1.groups
          this.setConditionList[0].conditionVos = [
            {
              caption: '',
              id: null,
              options: []
            }
          ]
          this.setConditionList[0].options.map((el) => {
            this.$set(el, 'isSelect', false)
          })
        }
        this.setPolicyConditionDialogVisible = true
      })
    },
    //查询设置条件详情
    async selectConditions() {
      const { data: res } = await selectConditions(this.setConditionQueryInfo)
      if (res.resultCode === 200) {
        if (res.data.length > 0) {
          //编辑
          this.setConditionEcho = '1'
          this.setConditionList = res.data
          this.setConditionList.forEach((item) => {
            if (!item.conditionVos) {
              item.conditionVos = [
                {
                  caption: '',
                  id: null,
                  options: []
                }
              ]
            }
          })
        } else {
          this.setConditionEcho = '0'
          this.setConditionList[0].correlationQuantity = 0
        }
      } else {
        this.$message.error('获取设置条件详情失败!')
      }
    },
    //设置条件弹窗 保存
    async savePolicyCondition() {
      let setConditionList = []
      this.setConditionList.forEach((item) => {
        item.conditionType = this.conditionType // 获取条件类型
        item.questionId = this.setConditionQuestionId // 获取当前问题id
        item.questionnaireId = this.id // 获取工具id
        let obj = {
          city: item.city,
          conditionType: item.conditionType,
          correlationQuantity: item.correlationQuantity,
          id: item.id,
          noCorrelationQuantity: item.noCorrelationQuantity,
          noPolicyId: '',
          noPolicyIdLists: item.noPolicyIdLists,
          noPolicyReason: item.noPolicyReason,
          optionIdList: item.optionIdList,
          optionIds: '',
          policyId: '',
          policyIdLists: item.policyIdLists,
          province: item.province,
          questionId: item.questionId,
          questionnaireId: item.questionnaireId,
          rawOptionId: '',
          rawOptionIdList: item.rawOptionIdList,
          score: item.score
        }
        setConditionList.push(obj)
      })
      const { data: res } = await addConditions(setConditionList)
      if (res.resultCode === 200) {
        this.setPolicyConditionDialogVisible = false
        this.$message.success('设置条件成功!')
        this.conditionType = '2'
      } else {
        this.$message.error('设置条件失败!')
      }
    },
    //设置条件弹窗关闭
    setPolicyConditionDialogClosed() {
      this.conditionType = '2'
      this.setPolicyConditionDialogVisible = false
    },
    //打开设置权重弹窗
    setProblemWeightBtn(item, i1) {
      this.setWeightForm.groupName = item.groupName
      this.setWeightDialogWeight = item.weight
      this.setWeightDialogScore = item.score
      this.setWeightForm.problemName = i1.groupName
      this.setWeightForm.id = i1.questionsOptionId
      this.setWeightForm.weight = i1.weight
      i1.groups.map((el) => {
        this.setWeightForm.weightParams.push({
          id: el.questionsOptionId,
          weight: el.weight,
          score: el.score,
          name: el.groupName
        })
      })
      if (i1.weight != 0) {
        let num1 = Number(item.score)
        let num2 = Number(i1.weight)
        this.setWeightProblemScore = this.getScore(num1, num2)
      }
      this.setProblemWeightDialogVisible = true
    },
    //问题权重改变
    setProblemWeightChanged(val) {
      if (val) {
        this.setWeightForm.weight = Number(val)
        let num1 = Number(val)
        let num2 = Number(this.setWeightDialogScore)
        this.setWeightProblemScore = this.getScore(num1, num2)
      }
    },
    //选项权重改变
    setOptionWeightChanged(val, item) {
      if (val) {
        item.weight = Number(val)
        let num1 = Number(val)
        let num2 = Number(this.setWeightProblemScore)
        item.score = this.getScore(num1, num2)
      }
    },
    //设置权重弹窗 保存
    async saveProblemWeight() {
      const { data: res } = await updateWeight(this.setWeightForm)
      if (res.resultCode === 200) {
        this.search().then(() => {
          this.setWeightForm = {
            id: 0, //关联id
            weight: null, //权重
            groupName: '', //问题组名
            problemName: '', //问题名
            weightParams: [] // 选项数据
          }
        })
        this.setProblemWeightDialogVisible = false
        this.$message.success('设置问题权重成功!')
      } else {
        this.$message.error('设置问题权重失败!')
      }
    },
    //设置权重弹窗关闭
    setProblemWeightDialogClosed() {
      this.setWeightForm = {
        id: 0, //关联id
        weight: null, //权重
        groupName: '', //问题组名
        problemName: '', //问题名
        weightParams: [] // 选项数据
      }
      this.setProblemWeightDialogVisible = false
    },
    //预览问卷 保存按钮
    saveQuestions() {
      console.log('保存')
    },
    //返回配置问题组界面
    goBack() {
      this.$emit('goBack')
    },
    //打开编辑问题组弹窗
    editGroup(item) {
      this.$emit('editProblemGroup', item)
    },
    // 配置问题按钮 传问题组id
    toConfigProblem(e, item) {
      let event = e || window.event
      //event.stopPropagation() //阻止事件冒泡
      if (event.stopPropagation) {
        event.stopPropagation() //非IE
      } else {
        event.cancelBubble = true //兼容IE
      }
      this.$emit('toConfigProblem', item) //select事件触发后，自动触发showCityName事件
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-flow: column;
  height: 60vh;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #fff;
  .row .row_right .left {
    padding-right: 30px;
    margin-right: 20px;
    border-right: #999 2px solid;
  }
}
.problem_group_list {
  max-height: 50vh;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .problem_group_item {
    .problem_num {
      font-weight: 500;
      font-size: 16px;
      color: #d4d4d4;
      margin-right: 20px;
    }
  }
  .problem_group_level2_list {
    display: flex;
    flex-flow: column;
    .problem_group_level2_item {
      display: flex;
      flex-flow: column;
      padding: 10px 20px;
      box-sizing: border-box;
      background-color: #f8f8f8;
      margin-bottom: 10px;
    }
    .problem_group_level3_list {
      display: flex;
      flex-flow: column;
      padding: 10px 20px;
      border-bottom: #999 1px dotted;
      box-sizing: border-box;
      .problem_group_level3_item {
        display: flex;
        flex-flow: column;
      }
    }
  }
}
.problem_list {
  padding: 10px;
  box-sizing: border-box;
  .problem_item:hover .span_btn_list {
    display: flex;
  }
  .problem_item {
    cursor: pointer;
    display: flex;
    padding: 10px;
    .item_icon_box {
      display: flex;
      align-items: center;
      position: relative;
      .hide_options_box {
        display: none !important;
      }
      .show_options_box_top {
        transform: translateY(-100%);
      }
      .show_options_box {
        display: block;
        width: 320px;
        padding: 10px 20px;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
        position: absolute;
        top: 0;
        left: 100%;
        .num {
          margin-right: 20px;
        }
        .options_box_top {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .problem_name {
            display: block;
            width: 120px;
            font-size: 16px;
            color: #fff;
            margin-right: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
        }
        .option_list {
          display: flex;
          flex-flow: column;
          border-top: #fff 1px dotted;
          .option_item {
            display: flex;
            align-items: center;
            margin: 10px 0;
            color: #fff;
            .option_name {
              display: block;
              width: 120px;
              color: #fff;
              font-size: 14px;
              margin-right: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .span_btn_list {
      min-width: 200px;
      display: none;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      .item {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.option_policy_list {
  display: flex;
  flex-flow: column;
  .option_box {
    display: flex;
    flex-flow: column;
    .el-autocomplete {
      width: 80%;
    }
  }
}
.score_box,
.area_box,
.time_box {
  display: flex;
  flex-flow: column;
  padding: 20px 0;
  box-sizing: border-box;
}
.condition_list {
  display: flex;
  flex-flow: column;
  padding: 10px 0;
  box-sizing: border-box;
  .condition_item {
    display: flex;
    padding: 10px 0;
    // margin-bottom: 10px;
    box-sizing: border-box;
    .item_content {
      display: flex;
      flex-flow: column;
      .el-radio {
        line-height: 40px;
      }
      .row {
        align-items: end;
        .input_txt {
          margin-left: 10px;
        }
      }
    }
    .row_span {
      font-size: 12px;
      color: #b8b8b8;
    }
    .condition_item_content {
      display: flex;
      flex-flow: column;
      max-width: 100%;
      padding: 10px 20px;
      border: #c4c4c4 1px dotted;
      box-sizing: border-box;
      .content_top {
        max-width: 100%;
        padding: 10px 20px 10px 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        background-color: #f6f6f6;
      }
      .content_bottom {
        border-top: #999 1px dotted;
        padding: 10px 0;
        margin-right: 20px;
      }
      .el-autocomplete {
        width: 80%;
      }
    }
  }
}
.dialog_label {
  min-width: 80px;
  line-height: 40px;
  // text-align: end;
  margin-right: 20px;
}
.dialog_txt {
  margin-left: 100px;
  font-size: 12px;
  color: #b8b8b8;
}
.box_list {
  .box_list_item {
    display: flex;
    margin-bottom: 10px;
  }
  .item_box {
    min-width: 80%;
    padding: 10px;
    border: #999 1px dotted;
  }
}
.cursor_row {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 40px;
  box-sizing: border-box;
}
.num_input ::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}

.name_one {
  font-weight: 500;
  font-size: 20px;
  color: #333;
  margin-right: 10px;
}
.name_two {
  font-weight: 500;
  font-size: 18px;
  color: #333;
  margin-right: 10px;
}
.problem_name {
  font-weight: 500;
  font-size: 16px;
  color: #252631;
  margin-right: 10px;
}
.dialog_content {
  margin-left: 20px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 560px;
  overflow-y: auto;
  .option_list {
    padding: 10px;
    box-sizing: border-box;
    border-top: #999 1px dotted;
  }
  .problem_group_weight_input {
    width: 50%;
  }
  .row_input_right {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    background-color: #f8f8f8;
  }
}
::v-deep .el-dialog .el-dialog__footer {
  text-align: center;
}
.border_icon {
  height: 10px;
  margin-right: 5px;
  border: #4e93fb 1px solid;
}
.i1_box {
  display: flex;
  align-content: center;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  i {
    font-size: 20px;
  }
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
}
.icon_box {
  display: flex;
  padding: 10px 5px;
  min-width: 60px;
  i {
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #4e93fb;
    margin-left: 5px;
  }
}
.txt_btn {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  span {
    // font-size: 16px;
    margin-right: 10px;
  }
}
.i_box {
  display: flex;
  align-items: center;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  i {
    font-size: 14px;
  }
  img {
    height: 14px;
    width: 14px;
  }
}
.num {
  margin-right: 10px;
}
.row {
  // width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  .row_item {
    margin-right: 20px;
  }
  .row_right {
    display: flex;
    margin-left: auto;
    margin-right: 20px;
  }
}
.flex_input {
  width: 80%;
}
.el-checkbox {
  margin-right: 10px;
}
span {
  font-size: 14px;
  color: #333;
}
.common_size {
  font-size: 16px;
}
.color1 {
  color: #999;
}
.color2 {
  color: #4e93fb;
}
.color3 {
  color: #666;
}
.color4 {
  color: #d4d4d4;
}
.color5 {
  color: #ff9c00;
}
.color7 {
  color: #f53939;
}
.color6 {
  color: #fff;
}
</style>
