<template>
  <div class="select_trouble">
    <div class="container">
      <div class="top_card">
        <div class="row">
          <span>问题组名称</span>
        </div>
        <div class="row"></div>
        <div class="row"></div>
      </div>
      <div class="content">
        <div class="row">
          <div class="search_input">
            <el-input
              placeholder="请输入问题标题"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
            >
              <el-button slot="append" type="primary" @click="search">搜索</el-button>
            </el-input>
          </div>
          <div class="txt">
            <span class="text">已选择问题</span>
            <span class="color1">{{ questionSelectedNum }}</span>
            <span>/</span>
            <span>{{ total }}</span>
          </div>
        </div>
        <div class="table_box">
          <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="编号" min-width="120" align="center">
              <template v-slot="{ row }">
                <span>G00{{ row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="caption" label="问题标题" min-width="180" align="center">
            </el-table-column>
            <el-table-column
              prop="descriptionOfOptions"
              label="问题说明"
              min-width="180"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <div class="btn_list">
          <el-button type="primary" @click="saveSelectedProblem">保 存</el-button>
          <el-button @click="goBack" plain>返回上一级</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { problemList } from '@/api/problem.js'
import { saveGroupQuestions } from '@/api/diagnoseTool.js'

const defaultQueryInfo = Object.freeze({
  city: '',
  country: '',
  evaluationType: '', //评估类型(1:企业数智化转型诊断评估,2:企业数智化转型政策申报)
  industryCategory: [], //行业类别集合
  isGroup: true, //是否在问题组中展示
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: '', //查询条件
  questionsType: '', //问题类型
  questionnaireId: 0 //工具id
})

export default {
  name: 'selectTrouble',
  components: {},
  props: {
    //问题组id
    questionnaireId: {
      type: Number
    },
    //问卷id
    id: {},
    active: {
      type: Number
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //
      tableData: [], //表格数据
      questionsIds: [], //问题id集合
      questionSelectedNum: 0, //选中的问题数
      total: 0 // 总条数
    }
  },
  watch: {
    /* questionnaireId: {
      handler() {
        this.search()
      },
      immediate: false
    }, */
    active: {
      handler() {
        if (this.active == 2) {
          this.search()
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 查询问题一览
    async search() {
      this.queryInfo.questionnaireId = this.id
      const { data: res } = await problemList(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取问题列表失败!')
      }
    },
    // 监听表格选中的值
    handleSelectionChange(el) {
      this.questionsIds = []
      el.map((item) => {
        this.questionsIds.push(item.id)
      })
      this.questionSelectedNum = this.questionsIds.length
    },
    //保存按钮
    async saveSelectedProblem() {
      let param = {
        newQuestionnaireId: this.id, //问卷id
        questionnaireId: this.questionnaireId, //问题组id
        questionsIds: this.questionsIds //问题id集合
      }
      if (param.questionsIds.length > 0) {
        const { data: res } = await saveGroupQuestions(param)
        if (res.resultCode === 200) {
          this.search()
          this.$message.success('保存成功!')
        } else {
          this.$message.error('保存失败!')
        }
      } else {
        this.$message.warning('请先选择问题!')
      }
    },
    //返回配置问题组界面
    goBack() {
      this.$emit('goBack')
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.top_card {
  width: 100%;
  min-height: 100px;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.content {
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .table_box {
    overflow-y: auto;
    width: 100%;
    max-height: 30vh;
  }
}
.search_input {
  width: 30%;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #448aff;
  }
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
}
.txt {
  margin-right: 20px;
  .text {
    color: #7c7f8e;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
  }
  span {
    font-size: 20px;
    font-weight: 500;
  }
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}
.pagination {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.color1 {
  color: #ff9c00;
}
</style>